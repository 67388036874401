import { useLayoutEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TWclassNames } from "../../../../utils/Div";
import { LoadingRows, ProjectTitle } from "../../../../Utils";
import { PMPagination } from "../../../../utils/PMPagination";
import { ResourceEmpty } from "../../../../utils/ResourceEmpty";

export const QualityChaptersTable = (props) => {
  const { itemState, onPageChange, itemsCount, currentCount, page, perPage } =
    props;
  const { data, isLoading, errors } = itemState;
  const chapters = data;

  const ChapterItem = ({ item }) => {
    return (
      <>
        <section
          className="lg:max-w[7xl]"
          aria-labelledby="applicant-information-title"
        >
          <div className="bg-white shadow sm:rounded-lg">
            <div className="flex items-center px-4 py-5 sm:px-6 w-full">
              <div className="flex justify-between w-full">
                <Link to={"/dashboard/quality/qualities/chapters/" + item?.id}>
                  <h2
                    id="applicant-information-title"
                    className="text-md font-medium leading-6"
                  >
                    #{item?.chapter_number}
                  </h2>
                  <h2 className="text-xl font-medium leading-6 text-gray-900">
                    {item?.title}
                  </h2>
                  <p className="italic text-gray-400">{item?.description}</p>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

  return (
    <div>
      <PMPagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        perPage={perPage}
        rowsCount={itemsCount}
        totalCount={itemsCount}
        currentCount={currentCount}
        showCount={false}
        showPages={false}
      />
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              {chapters?.length > 0 &&
                chapters?.map((item, idx) => (
                  <div
                    key={idx}
                    className="lg:col-span-2 lg:col-start-1 sm:col-span-2"
                  >
                    <ChapterItem item={item} />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <PMPagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        perPage={perPage}
        rowsCount={itemsCount}
        totalCount={itemsCount}
        currentCount={currentCount}
        showCount={true}
      />
    </div>
  );
};

QualityChaptersTable.defaultProps = {
  page: 1,
  itemState: [],
  itemsCount: 0,
  selectedItems: [],
  sort: "desc",
  sortBy: "createdAt",
};
