import { Fragment, useEffect, useState } from "react";
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
  MapPinIcon,
} from "@heroicons/react/20/solid";
import { Menu, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { TWclassNames } from "../../../../../components/utils/Div";
import { BigDatePicker } from "../../../../../components/utils/calendar/bigDatePicker";
import { getReportsAsync } from "../../../../../slices/service/reports";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ResourceLoading } from "../../../../../components/utils/ResourceLoading";
import { ResourceEmpty } from "../../../../../components/utils/ResourceEmpty";
import { toast } from "react-hot-toast";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { DevicePhoneMobileIcon, WrenchIcon } from "@heroicons/react/24/outline";
import { ReportAddress } from "../../../../../components/dashboard/service/reports/report-address";
import {
  appointmentApprovedStatuses,
  FullReportAddress,
  ReportsRouteButton,
  reportStatuses,
  statuses,
} from "../../../../../components/dashboard/service/reports/utils";
import PMCombobox from "../../../../../components/utils/PMCombobox";
import { fetchUsers } from "../../../../../api/users";
import PMListbox from "../../../../../components/utils/PMListbox";
import {
  dateToString,
  updateUrlWithException,
} from "../../../../../components/Utils";
import { debounce } from "lodash";

export default function ReportsCalendarSimpleView(props) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const today = new Date();

  var date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();

  const [selectedDate, setSelectedDate] = useState(
    searchParams.get("date")
      ? searchParams.get("date")
      : date.toISOString().substring(0, 10)
  );

  const dispatch = useDispatch();
  const itemState = useSelector((store) => {
    return store.reports;
  });

  const currentUserSession = useSelector((state) => state.session.currentUser);

  const [userOptions, setUserOptions] = useState({
    isLoading: true,
    error: false,
    errors: [],
    data: [],
  });

  async function fetchAndMapUsers() {
    setUserOptions((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    // Fetch Case types from API?
    const controller = {
      filters: [{ name: "role", value: "service" }],
      page: 1,
      query: "",
      sort: "desc",
      sortBy: "id",
      view: "all",
    };

    await fetchUsers(controller)
      .then((res) => {
        if (res.data) {
          var array = [];
          if (!res.data) return array;
          res.data.map((row, i) => {
            const rowParams = {
              value: row.id,
              label: row.name,
              order: i,
            };
            array.push(rowParams);
          });
          setUserOptions((prevState) => ({
            ...prevState,
            isLoading: false,
            data: array,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        setUserOptions((prevState) => ({
          ...prevState,
          isLoading: false,
          error: true,
          errors: errors,
        }));
      });

    // Map into an array with ID and title?

    // Return the array
  }

  useEffect(() => {
    fetchAndMapUsers();
  }, []);

  const [currentUser, setCurrentUser] = useState(
    searchParams.get("user") ? searchParams.get("user") : currentUserSession.id
  );

  function handleUserChange(data) {
    setCurrentUser(data);
  }

  const [controller, setController] = useState({
    filters: [
      {
        name: "agreement_date",
        value: selectedDate,
      },
      {
        name: "executing_employee",
        value: currentUser,
      },
    ],
    page: searchParams.get("page") ? searchParams.get("page") : 1,
    per_page: searchParams.get("per_page") ? searchParams.get("per_page") : 100,
    query: searchParams.get("query") ? searchParams.get("query") : "",
    sort: searchParams.get("sort") ? searchParams.get("sort") : "asc",
    sortBy: searchParams.get("sortBy") ? searchParams.get("sortBy") : "time",
    view: searchParams.get("view") ? searchParams.get("view") : "all",
    fields:
      "id,status,description,appointment,customer.mobile,customer.displayTitle,displayTitle,report_type.displayTitle,report_type.id,delivery_address",
  });

  useEffect(() => {
    updateUrlWithException(
      // These will be updated
      {
        user: currentUser,
        date: selectedDate,
        page: controller.page,
        per_page: controller.per_page,
        query: controller.query,
        sort: controller.sort,
        sortBy: controller.sortBy,
        view: controller.view,
      },
      // This will be preserved
      "filters"
    );
  }, [controller]);

  const handleFiltersChange = (newPage) => {
    setController({
      ...controller,
      filters: [
        { name: "agreement_date", value: selectedDate },
        { name: "executing_employee", value: currentUser },
      ],
    });
  };

  useEffect(() => {
    handleFiltersChange();
  }, [selectedDate, currentUser]);

  const debouncedDispatchAction = debounce((controller) => {
    dispatch(getReportsAsync(controller));
  }, 250);

  useEffect(() => {
    // Trigger the debounced dispatch action
    debouncedDispatchAction(controller);

    // Clean the debounced function on component unmount.
    return () => {
      debouncedDispatchAction.cancel();
    };
  }, [controller, dispatch]);

  const { data, isLoading } = itemState;

  const navigate = useNavigate();

  function navigateToReport(report) {
    navigate(`/dashboard/service/reports/${report.id}`);
  }

  if (isLoading) return <ResourceLoading />;

  return (
    <div className="bg-white px-5 py-5 rounded-2xl shadow ">
      <h2 className="text-base font-semibold leading-6 text-gray-900 mb-2">
        {t("Reports Calendar")} for
        {/* <PMCombobox
          formik={false}
          name="user_assigned_id"
          options={userOptions.data}
          value={currentUserSession?.id}
          onChange={(data) => handleUserChange(data)}
        /> */}
      </h2>
      <PMListbox
        options={userOptions.data}
        selected={currentUser}
        onChange={(data) => handleUserChange(data.value)}
      />
      <ReportsRouteButton params={controller} />

      <div className="lg:grid lg:grid-cols-12 lg:gap-x-16">
        <div className="lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9">
          <BigDatePicker
            selectedDate={selectedDate}
            onChange={(date) => setSelectedDate(date)}
          />
        </div>

        {isLoading ? (
          <div className="mt-10 h-full w-full lg:col-span-7 xl:col-span-8">
            <ResourceLoading />
          </div>
        ) : data < 1 ? (
          <div className="lg:col-span-7 xl:col-span-8">
            <ResourceEmpty />
          </div>
        ) : (
          <ol className="mt-4 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-8">
            {data?.map((report) => {
              return (
                <li
                  key={report.id}
                  className="cursor-pointer relative flex space-x-6 py-6 border-2 border-blue-400 xl:static hover:bg-blue-200 rounded-xl p-2"
                >
                  <WrenchIcon className="h-14 w-14 flex-none rounded-full" />
                  <div className="flex-auto">
                    {report?.status && (
                      <span
                        className={TWclassNames(
                          reportStatuses[report?.status].class,
                          "inline-flex rounded-full px-3 text-xs font-semibold leading-5 mb-2"
                        )}
                      >
                        {t(reportStatuses[report?.status].label)}
                      </span>
                    )}
                    {report?.appointment?.date && (
                      <span
                        className={TWclassNames(
                          appointmentApprovedStatuses[
                            report?.appointment?.approved
                          ].class,
                          "inline-flex rounded-full px-3 text-xs font-semibold leading-5 mb-2 ml-2"
                        )}
                      >
                        {t(
                          appointmentApprovedStatuses[
                            report?.appointment?.approved
                          ].label
                        )}
                      </span>
                    )}

                    <div className="flex items-start">
                      <h3
                        onClick={() => navigateToReport(report)}
                        className="flex flex-col pr-10 font-semibold text-gray-900 xl:pr-0"
                      >
                        <span>
                          {report?.displayTitle +
                            " - " +
                            report?.customer?.displayTitle}
                        </span>
                        <span className="italic">
                          {report?.report_type?.displayTitle}
                        </span>
                      </h3>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{ __html: report?.description }}
                    />
                    <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
                      <div className="flex items-start space-x-3">
                        <dt className="mt-0.5">
                          <span className="sr-only">Date</span>
                          <CalendarIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </dt>
                        <dd>
                          <time
                            dateTime={report.appointment?.date}
                            className="flex flex-col"
                          >
                            <span>
                              {dateToString(report?.appointment?.date)}
                            </span>
                            {report?.appointment?.time ? (
                              <span>{report?.appointment?.time}</span>
                            ) : (
                              <span>
                                {t("from") + " "}
                                {report?.appointment?.time_from} {t("to") + " "}
                                {report?.appointment?.time_too}
                              </span>
                            )}
                          </time>
                        </dd>
                      </div>
                      <div className="mt-2 flex items-start space-x-3 xl:mt-0 xl:ml-3.5 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                        <dt className="mt-0.5">
                          <span className="sr-only">Location</span>
                          <MapPinIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </dt>
                        <dd>
                          <a
                            href={
                              "https://www.google.com/maps?q=" +
                              FullReportAddress(report)
                            }
                            className="flex flex-col"
                          >
                            <span>
                              {FullReportAddress(report).split(",")[0]}
                            </span>
                            <span>
                              {FullReportAddress(report).split(",")[1]}
                            </span>
                          </a>
                        </dd>
                      </div>

                      <div className="mt-2 flex items-start space-x-3 xl:mt-0 xl:ml-3.5 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                        <dt className="mt-0.5">
                          <span className="sr-only">Mobile</span>
                          <DevicePhoneMobileIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </dt>
                        <dd>
                          <a href={"tel:" + report?.customer?.mobile}>
                            {report?.customer?.mobile}
                          </a>
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <Menu
                    as="div"
                    className="absolute top-6 right-0 xl:relative xl:top-auto xl:right-auto xl:self-center"
                  >
                    <div>
                      <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-500 hover:text-gray-600">
                        <span className="sr-only">Open options</span>
                        <EllipsisHorizontalIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={TWclassNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                Edit
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={TWclassNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                Cancel
                              </a>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </li>
              );
            })}
          </ol>
        )}
      </div>
    </div>
  );
}
