import { Fragment } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Menu, Transition } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import { CopyToClipboard, TWclassNames } from "../utils/Div";
import Breadcrumbs from "./breadcrumbs";
import { SeoHelmetWrapper } from "../Utils";

export default function DashboardHeader(props) {
  const { title, subtitle, actions, meta, children, mainButton } = props;

  const navigate = useNavigate();

  return (
    <>
      <SeoHelmetWrapper
        title={title}
        description={subtitle}
        subtitle={subtitle}
      />
      <div className="bg-white">
        <Breadcrumbs currentPageTitle={title} />

        <div className="mx-auto py-5 sm:px-6 lg:max-w-7xl flex w-full items-center justify-between px-3 lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1 mb-10">
            <h2 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              <CopyToClipboard>{title}</CopyToClipboard>
            </h2>
            <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
              {meta?.map((meta, index) => {
                if (!meta.hidden) {
                  return (
                    <>
                      <div
                        onClick={
                          meta.action
                            ? meta.action
                            : ["http", "mail", "tel:"].includes(
                                meta?.path?.substring(0, 4)
                              )
                            ? () => window.location.replace(meta.path)
                            : () => navigate(meta.path)
                        }
                        key={index}
                        className={TWclassNames(
                          meta.action || meta.path ? "cursor-pointer" : "",
                          meta.textColor ? meta.textColor : "text-gray-500",
                          "mt-2 flex items-center text-sm text-gray-500"
                        )}
                      >
                        {meta.component ? (
                          meta.component
                        ) : (
                          <>
                            <meta.icon
                              className={TWclassNames(
                                meta.iconColor
                                  ? meta.iconColor
                                  : "text-gray-400",
                                "mr-1.5 h-5 w-5 flex-shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {meta.label}
                          </>
                        )}
                      </div>

                      {meta?.copy ? (
                        <CopyToClipboard type="button" value={meta.label} />
                      ) : null}
                    </>
                  );
                }
              })}
            </div>
          </div>
          <div className="mt-5 grid gap-4 sm:gap-0 sm:flex lg:ml-4 lg:mt-0">
            {/* Map actions that is not hidden */}

            {actions &&
              actions
                ?.filter(
                  (action) => action.hidden === false && action.menu === false
                )
                ?.map((action, index) => {
                  return (
                    <span
                      key={index}
                      className={TWclassNames(
                        action.menu ? "hidden" : "block",
                        "sm:block ml-3"
                      )}
                    >
                      {action?.component ? (
                        action?.component
                      ) : (
                        <button
                          onClick={
                            action.action
                              ? action.action
                              : action?.path?.substring(0, 4) === "http"
                              ? () => window.location.replace(action.path)
                              : () => navigate(action.path)
                          }
                          type="button"
                          className={TWclassNames(
                            action.textColor
                              ? action.textColor
                              : "text-gray-900",
                            action.bgColor ? action.bgColor : "bg-white",
                            "inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold  shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          )}
                        >
                          <action.icon
                            className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          {action.label}
                        </button>
                      )}
                    </span>
                  );
                })}

            {/* Main button START */}
            {mainButton && (
              <span className="ml-3">
                <button
                  onClick={
                    mainButton.action
                      ? mainButton.action
                      : mainButton?.path?.substring(0, 4) === "http"
                      ? () => window.location.replace(mainButton.path)
                      : () => navigate(mainButton.path)
                  }
                  type="button"
                  className="inline-flex items-center rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  <mainButton.icon
                    className="-ml-0.5 mr-1.5 h-5 w-5"
                    aria-hidden="true"
                  />
                  {mainButton.label}
                </button>
              </span>
            )}
            {/* Main button END */}

            {/* Dropdown */}
            {actions?.filter(
              (action) => action.hidden === false && action.menu === true
            ).length > 0 && (
              <Menu as="div" className="relative ml-3">
                <Menu.Button className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400">
                  More
                  <ChevronDownIcon
                    className="-mr-1 ml-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {actions &&
                      actions
                        ?.filter(
                          (action) =>
                            action.hidden === false && action.menu === true
                        )
                        ?.map((action, index) => {
                          return (
                            <Menu.Item key={index}>
                              {({ active }) => (
                                <a
                                  onClick={
                                    action.action
                                      ? action.action
                                      : action?.path?.substring(0, 4) === "http"
                                      ? () =>
                                          window.location.replace(action.path)
                                      : () => navigate(action.path)
                                  }
                                  type="button"
                                  className={TWclassNames(
                                    action.textColor
                                      ? action.textColor
                                      : "text-gray-900",
                                    action.bgColor
                                      ? action.bgColor
                                      : "bg-white",
                                    "block text-sm hover:bg-blue-100 py-4 px-4 cursor-pointer"
                                  )}
                                >
                                  <div className="flex">
                                    <action.icon
                                      className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                    {action.label}
                                  </div>
                                </a>
                              )}
                            </Menu.Item>
                          );
                        })}
                  </Menu.Items>
                </Transition>
              </Menu>
            )}
          </div>
        </div>
      </div>
      <div className="mb-10">{children}</div>
    </>
  );
}
