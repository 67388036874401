import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";

import { TextField, TableFooter, Button } from "@mui/material";
import { toast } from "react-hot-toast";
import { TrashIcon } from "@heroicons/react/24/outline";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import ProConfirmation from "../../../utils/ProConfirmation";
import { generateRandomString } from "../../../Utils";
import PMSwitch from "../../../utils/PMSwitch";
import { UploaderV2 } from "../../Media/Uploaderv2";
import { DocumentViewer } from "../../Media/documentViewer";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 20,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgrey" : null,

  // styles we need to apply on draggables
  ...draggableStyle,
});

export default function ChecklistTypeItemLines(props) {
  const { setFieldValue, values, isLoading, name, drag, editable, type } =
    props;

  const rows = values?.items || props?.items;

  // Reordered items by updating their row_order
  const orderItems = (rows) => {
    const array = [];

    if (!rows) return;

    rows.map((row, i) => {
      // Ensure that the id exists before using it

      // Generate a random string (10 characters)
      const random = generateRandomString();

      const rowParams = {
        ...row,
        row_order: i,
        draggableId: random,
      };

      array.push(rowParams);
    });

    return array;
  };

  // Title column is only used when group_row is true
  const defaultRows = [
    {
      id: "0",
      row_order: null,
      description: "",
    },
  ];

  const [items, setItems] = useState(orderItems(rows ? rows : defaultRows));

  useEffect(() => {
    if (setFieldValue) {
      setFieldValue(name, items);
    }
  }, [items]);

  const addItemRow = (type) => {
    const newRow = {
      title: "",
      description: "",
      avvik: false,
      row_order: items.length,
      documents_required: false,
    };
    const updatedItems = [...items, newRow];
    setItems(orderItems(updatedItems));
    // setItems((prevState) => [...prevState, newRow]);
  };

  const onItemRowChange = (e, data) => {
    // setItems(prevState => ({ ...prevState, [1][description]: 'test' }))
    if (typeof e === "function") {
      e.preventDefault();
    }

    let prevItems = [...items];
    let item = {
      ...prevItems[data.row_order],
      [e.target.name]: e.target.value || e.target.checked,
    };
    prevItems[data.row_order] = item;
    setItems(prevItems);
  };

  // Only approved or deviation can be true, not both
  const onApprovedChange = (e, data) => {
    let prevItems = [...items];
    let item = {
      ...prevItems[data.row_order],
      isApproved: e.target.checked,
      isDeviation: false,
    };
    prevItems[data.row_order] = item;
    setItems(prevItems);
  };

  const onDeviationChange = (e, data) => {
    let prevItems = [...items];
    let item = {
      ...prevItems[data.row_order],
      isDeviation: e.target.checked,
      isApproved: false,
    };
    prevItems[data.row_order] = item;
    setItems(prevItems);
  };

  const switchChange = (e, data) => {
    let prevItems = [...items];
    let item = {
      ...prevItems[data.row_order],
      [e.target.name]: e.target.checked,
    };
    prevItems[data.row_order] = item;
    setItems(prevItems);
  };

  const [createDeviationDialogOpen, setCreateDeviationDialogOpen] =
    useState(false);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(orderItems(newItems));
  };

  const [selectedItemRow, setSelectedItemRow] = useState();

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const handleDeleteRow = () => {
    setDeleteConfirmationOpen(false);
    // Delete selectedItem row from array
    if (selectedItemRow) {
      const newArray = items.filter(
        (item) => item.row_order !== selectedItemRow
      );
      setItems(orderItems(newArray));
    }
  };

  const deleteRow = (row_order) => {
    setSelectedItemRow(row_order);
    setDeleteConfirmationOpen(true);
  };

  const UploaderCallback = (data, row) => {
    // Documents handler

    // Add documents to documents inside the item object
    const newItems = [...items];
    const index = newItems.findIndex((item) => item.id === row.id);
    newItems[index].documents = [...newItems[index].documents, data];
    setItems(orderItems(newItems));
  };

  useEffect(() => {
    if (props.items && props.parentCallback) {
      props.parentCallback(items);
    }
  }, [items]);

  const itemRow = (item, provided, snapshot) => {
    return (
      <div
        key={"div" + item.id}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        // style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        className="flex flex-row mt-5 bg-white shadow px-10 py-6 rounded-md gap-4 justify-between"
      >
        {drag && (
          <div className="">
            <DragHandleIcon className="mt-5" />
          </div>
        )}

        <div className="flex-1">
          <div className="py-4 pb-4">
            {editable && (
              <PMSwitch
                label="Dokumenter påkrevd"
                name="documents_required"
                checked={item?.documents_required}
                onChange={(e) => switchChange(e, item)}
              />
            )}
          </div>

          <TextField
            disabled={!editable}
            label="Title"
            variant="standard"
            value={item?.title}
            fullWidth
            name="title"
            onChange={(e) => onItemRowChange(e, item)}
          />
          <TextField
            disabled={!editable}
            label="Beskrivelse"
            variant="standard"
            sx={{ mt: "15px" }}
            value={item?.description}
            fullWidth
            name="description"
            multiline
            rows={3}
            onChange={(e) => onItemRowChange(e, item)}
          />
        </div>

        <div className="flex-0">
          <TrashIcon
            className="cursor-pointer h-5 h-5 text-red-400"
            onClick={() => deleteRow(item.row_order)}
          />
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <ResourceLoading />;
  }

  return (
    <React.Fragment>
      <div className="flex flex-col ">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {items?.map((item, index) => (
                  <Draggable
                    isDragDisabled={!drag}
                    key={"drag" + item.id}
                    draggableId={item.draggableId}
                    index={index}
                  >
                    {(provided, snapshot) => itemRow(item, provided, snapshot)}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <Table>
        <TableFooter>
          <div>
            <div colSpan={2}>
              <Button onClick={() => addItemRow()}>Ny rad</Button>
            </div>
          </div>
        </TableFooter>
      </Table>

      <ProConfirmation
        severity="confirmation"
        open={deleteConfirmationOpen}
        message={"Are you sure you want to delete this row?"}
        action="delete"
        module="order_item"
        // item_id={confirmation.item_id}
        yesCallback={() => handleDeleteRow(selectedItemRow)}
        cancelCallback={() => setDeleteConfirmationOpen(false)}
      />
    </React.Fragment>
  );
}

ChecklistTypeItemLines.defaultProps = {
  name: "items",
  drag: true,
  delete: true,
  editable: true,
  type: "checklist",
};
