import produce from "immer";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchQualityChapters,
  fetchQualityChapter,
  updateQualityChapter,
  createQualityChapter,
  deleteQualityChapter,
} from "../../api/quality/quality_chapter";

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  data: [],
  totalCount: 0,
  currentCount: 0,
  newCount: 0,
};

export const initialController = {
  filters: [],
  page: 0,
  query: "",
  sort: "desc",
  sortBy: "created_at",
  view: "all",
};

export const deleteQualityChapterAsync = createAsyncThunk(
  "chapters/deleteQualityChapterAsync",
  async (payload) => {
    let response = await deleteQualityChapter(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const createQualityChapterAsync = createAsyncThunk(
  "chapters/createQualityChapterAsync",
  async (payload) => {
    let response = await createQualityChapter(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const updateQualityChapterAsync = createAsyncThunk(
  "chapters/updateQualityChapterAsync",
  async (payload) => {
    let response = await updateQualityChapter(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);
export const getQualityChaptersAsync = createAsyncThunk(
  "chapters/getQualityChaptersAsync",
  async (controller = initialController, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchQualityChapters(controller);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/))
      return rejectWithValue(true);

    // If all good, return response.
    return response;
  }
);

export const getQualityChapterAsync = createAsyncThunk(
  "chapters/getQualityChapterAsync",
  async (id, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchQualityChapter(id);

    // If errors, reject and return response
    if (response.error) return rejectWithValue(response);

    // If all good, return response.
    return response;
  }
);

const qualityChapterSlice = createSlice({
  name: "chapters",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getQualityChaptersAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getQualityChaptersAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalCount = action.payload.totalCount;
        state.currentCount = action.payload.currentCount;
        state.newestQualityChaptersCount = action.payload.newCount;
      })
      .addCase(getQualityChaptersAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to fetch variable types....is controller missing?",
        ];
      })
      .addCase(updateQualityChapterAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateQualityChapterAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          const index = draftState.data.findIndex(
            (type) => type.id === action.payload.id
          );
          draftState.data[index] = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(updateQualityChapterAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update chapter"];
      })
      .addCase(createQualityChapterAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createQualityChapterAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data.push(action.payload);
          draftState.isLoading = false;
        });
      })
      .addCase(createQualityChapterAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update chapter"];
      })
      .addCase(deleteQualityChapterAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteQualityChapterAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(deleteQualityChapterAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update chapter"];
      })
      .addCase(getQualityChapterAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getQualityChapterAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.chapter = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(getQualityChapterAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update chapter."];
      });
  },
});

// export const {} = customerSlice.actions;

export const { reducer } = qualityChapterSlice;

export default qualityChapterSlice;
