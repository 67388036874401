import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { TWclassNames } from "../Div";

export const BigDatePicker = (props) => {
  function getCurrentDate() {
    const urlParams = new URLSearchParams(window.location.search);
    const dateParam = urlParams.get("date");

    // Validate date format (YYYY-MM-DD)
    if (dateParam && /^\d{4}-\d{2}-\d{2}$/.test(dateParam)) {
      return new Date(dateParam);
    }

    // Return today's date if no valid dateParam is provided
    return new Date();
  }

  const initialDate = getCurrentDate();
  const { onChange } = props;

  const [selectedDate, setSelectedDate] = useState(
    initialDate.toISOString().substring(0, 10)
  );
  const [currentMonth, setCurrentMonth] = useState(initialDate.getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(initialDate.getFullYear());

  const handleOnChange = (day) => {
    setSelectedDate(day.date);
    onChange(day.date);
  };

  const handleMonthChange = (newMonth) => {
    let newYear = currentYear;
    if (newMonth > 12) {
      newMonth = 1;
      newYear = currentYear + 1;
    }
    if (newMonth < 1) {
      newMonth = 12;
      newYear = currentYear - 1;
    }
    setCurrentMonth(newMonth);
    setCurrentYear(newYear);
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getDaysForCurrentMonth = () => {
    const days = [];
    const today = new Date();
    const firstDayOfMonth = new Date(currentYear, currentMonth - 1, 1);
    const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
    const firstDayOfMonthIndex = firstDayOfMonth.getDay();
    const daysBefore = firstDayOfMonthIndex - 2;
    const daysAfter = 42 - (daysBefore + lastDayOfMonth);
    const totalDays = daysBefore + lastDayOfMonth + daysAfter;

    for (let i = 1; i <= totalDays; i++) {
      const day = i - daysBefore;
      const isCurrentMonth = day > 0 && day <= lastDayOfMonth;
      const isToday =
        isCurrentMonth &&
        today.getDate() === day &&
        today.getMonth() + 1 === currentMonth &&
        today.getFullYear() === currentYear;

      const date = new Date(currentYear, currentMonth - 1, day)
        .toISOString()
        .substring(0, 10);

      const isSelected = date === selectedDate;

      days.push({ date, isCurrentMonth, isToday, isSelected });
    }
    return days;
  };

  const [currentDays, setCurrentDays] = useState(getDaysForCurrentMonth());

  useEffect(() => {
    setCurrentDays(getDaysForCurrentMonth());
  }, [currentMonth, currentYear, selectedDate]);

  return (
    <div className="text-center ">
      <div className="flex items-center text-gray-900">
        <button
          onClick={() => handleMonthChange(currentMonth - 1)}
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <div className="flex-auto text-sm font-semibold">
          {monthNames[currentMonth - 1] + " " + currentYear}
        </div>
        <button
          onClick={() => handleMonthChange(currentMonth + 1)}
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
        <div>M</div>
        <div>T</div>
        <div>W</div>
        <div>T</div>
        <div>F</div>
        <div>S</div>
        <div>S</div>
      </div>
      <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
        {currentDays.map((day, dayIdx) => (
          <button
            key={day.date}
            type="button"
            onClick={() => handleOnChange(day)}
            className={TWclassNames(
              "py-1.5 hover:bg-gray-100 focus:z-10",
              day.isCurrentMonth ? "bg-white" : "bg-gray-50",
              (day.isSelected || day.isToday) && "font-semibold",
              day.isSelected && "text-white",
              !day.isSelected &&
                day.isCurrentMonth &&
                !day.isToday &&
                "text-gray-900",
              !day.isSelected &&
                !day.isCurrentMonth &&
                !day.isToday &&
                "text-gray-400",
              day.isToday && !day.isSelected && "text-indigo-600",
              dayIdx === 0 && "rounded-tl-lg",
              dayIdx === 6 && "rounded-tr-lg",
              dayIdx === currentDays.length - 7 && "rounded-bl-lg",
              dayIdx === currentDays.length - 1 && "rounded-br-lg"
            )}
          >
            <time
              dateTime={day.date}
              className={TWclassNames(
                "mx-auto flex h-7 w-7 items-center justify-center rounded-full",
                day.isSelected && day.isToday && "bg-indigo-600",
                day.isSelected && !day.isToday && "bg-gray-900"
              )}
            >
              {day.date.split("-").pop().replace(/^0/, "")}
            </time>
          </button>
        ))}
      </div>
    </div>
  );
};
