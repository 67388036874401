import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { axiosWithToast, dispatchWithToast } from "../../../../Utils";
import { Form, Formik } from "formik";
import toast from "react-hot-toast";
import { PMField } from "../../../../utils/PMField";
import { LoadingButton } from "@mui/lab";
import { CheckBadgeIcon, XMarkIcon } from "@heroicons/react/24/outline";
import * as Yup from "yup";
import { PMDialog } from "../../../../utils/PMDialog";
import { set } from "lodash";
import { QuillEditor } from "../../../../utils/quill-editor";
import {
  createQualityAsync,
  updateQualityAsync,
} from "../../../../../slices/quality/quality";
import { fetchQuality } from "../../../../../api/quality/quality";

export default function QualityDialog(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [itemState, setItemState] = useState({
    isLoading: true,
    error: false,
    errors: [],
    quality: {},
  });

  // Deconstructing props
  const { open, onClose, selected, onRefresh, redirect, chapter } = props;

  // Deconstruct itemState (Store)
  const { quality, isLoading, error, errors } = itemState;

  const [readOnly, setReadOnly] = useState(props.readOnly);

  const getChapter = async () => {
    axiosWithToast(
      fetchQuality(selected?.id),
      () => {},
      () => {
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      },
      (data) => {
        setItemState((prevState) => ({
          ...prevState,
          quality: data,
          isLoading: false,
        }));
      },
      "Loadng quality..."
    );
  };

  useEffect(() => {
    if (open) {
      if (selected?.id) {
        getChapter();
      } else {
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          quality: {},
          error: false,
          errors: [],
        }));

        setReadOnly(false);
      }
    }
  }, [dispatch, open, selected]);

  const createOrUpdateDispatch = (data) => {
    if (quality?.id) {
      const res = dispatch(updateQualityAsync(data));
      return res;
    } else {
      const res = dispatch(createQualityAsync(data));
      return res;
    }
  };

  const ChapterForm = () => {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          id: quality?.id,
          title: quality?.title,
          text: quality?.text,
          chapter_id: quality?.chapter_id || chapter?.id,
          submit: null,
        }}
        validationSchema={Yup.object({
          email: Yup.string().email(),
          // .required(t("Email is required")),
        })}
        onSubmit={(values, helpers) => {
          try {
            dispatchWithToast(
              createOrUpdateDispatch(values),
              (data) => onRefresh(data),
              onClose
            );
          } catch (err) {
            toast.error("Something went wrong...");
            helpers.setStatus({ success: false });
            helpers.setErrors({ submit: err.message });
            // helpers.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <Form>
            <div className="grid grid-cols-6 gap-6 mt-10">
              <div className="col-span-6 sm:col-span-6">
                <PMField
                  name="title"
                  type="text"
                  label={t("Tittel")}
                  {...formik}
                />
              </div>

              <div className="col-span-6 sm:col-span-6">
                <QuillEditor name="text" rows={4} {...formik} />
              </div>
            </div>
            <div className="border-top border-2 border-blue-400 mt-10" />

            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                type="button"
                className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                onClick={onClose}
              >
                {t("Close")}{" "}
              </button>

              {readOnly ? null : (
                <LoadingButton
                  loading={isLoading}
                  disabled={isLoading}
                  loadingPosition="start"
                  startIcon={<CheckBadgeIcon />}
                  type="button"
                  variant="contained"
                  color={"success"}
                  onClick={formik.submitForm}
                >
                  {quality.id ? t("Update") : t("Create")}
                </LoadingButton>
              )}
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  const [selectedTab, setSelectedTab] = useState(0);

  const handleRefresh = (data) => {
    setItemState((prevState) => ({
      ...prevState,
      quality: data,
      isLoading: false,
    }));
  };

  const handleLoading = () => {
    setItemState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
  };

  const modalHeaderActions = [
    {
      label: t("Steng"),
      icon: XMarkIcon,
      action: () => onClose(),
    },
  ];

  return (
    <PMDialog
      open={open}
      onClose={onClose}
      headerActions={modalHeaderActions}
      isLoading={isLoading}
      size={"5xl"}
      title={
        readOnly
          ? quality?.id
            ? quality?.title
            : t("Retningslinje")
          : quality?.id
          ? quality?.displayTitle
          : t("Opprett retningslinje")
      }
    >
      <>
        <ChapterForm />
      </>
    </PMDialog>
  );
}

QualityDialog.defaultProps = {
  selected: null,
  // Read only is used when only wanting to display info. Update or creating will not work.
  readOnly: false,
  open: false,
  selection: false,
  onClose: () => toast.error("Missing onClose function. Cannot close dialog"),
  redirect: false,
};
