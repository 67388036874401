import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { PMField } from "../../../utils/PMField";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dispatchWithToast, mapErrors } from "../../../Utils";
import PMSwitch from "../../../utils/PMSwitch";
import {
  createReportAsync,
  updateReportAsync,
} from "../../../../slices/service/reports";
import {
  createReportChecklist,
  deleteReportChecklist,
  fetchReport,
} from "../../../../api/service/report";
import { QuillEditor } from "../../../utils/quill-editor";
import { DeliveryAddressSelector } from "../../../utils/moduleSelectors/deliveryAddressSelector";
import { SystemSelector } from "../../../utils/moduleSelectors/systemSelector";
import { ProjectSelector } from "../../../utils/moduleSelectors/projectSelector";
import { CustomerSelector } from "../../../utils/moduleSelectors/customerSelector";
import { ReportTypeSelector } from "../../../utils/moduleSelectors/reportTypeSelector";
import OrderItems from "../../orders/order-items";
import PMSteps from "../../../utils/PMSteps";
import ActivitiesTable from "../../activities/activitiesTable";
import { ProjectFolderSelector } from "../../../utils/moduleSelectors/projectFolderSelector";
import PMCombobox from "../../../utils/PMCombobox";
import { getChecklistTypeOptions } from "../../quality/checklists/utils";
import ChecklistItemLines from "../../quality/checklists/checklistItemLines";
import { PMNotice } from "../../../utils/notifications";
import { PMDialog } from "../../../utils/PMDialog";
import { ContactsSelector } from "../../../utils/moduleSelectors/contactSelector";
import { PMButton } from "../../../utils/PMButton";
import { TemplateDialogButton } from "../../templates/template-dialog-button";
import SimplePrompt from "../../../utils/simplePrompt";
import { useUserHasAccess } from "../../../../routes/roleOnly";
import { ResourceLoading } from "../../../utils/ResourceLoading";

export default function ServiceReportEditModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [itemState, setItemState] = useState({
    isLoading: true,
    error: false,
    errors: [],
    report: {},
  });

  const {
    open,
    onClose,
    selected,
    fields,
    project,
    customer,
    report_type,
    redirect,
    contact,
  } = props;

  const { report, isLoading, error, errors } = itemState;

  const [isNew, setIsNew] = useState(false);

  // Set isLoading on dispatches
  useEffect(() => {
    setItemState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
  }, [dispatch]);

  useEffect(() => {
    if (!report?.id) {
      setIsNew(true);
    } else {
      setIsNew(false);
    }
  }, [report]);

  const getReport = async () => {
    await fetchReport(selected.id)
      .then((res) => {
        if (res.data.id) {
          setItemState((prevState) => ({
            ...prevState,
            isLoading: false,
            report: res.data,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err.data.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          report: {},
          error: true,
          errors: errors,
        }));
      });
  };

  const createOrUpdateDispatch = (data) => {
    if (report?.id) {
      const res = dispatch(updateReportAsync(data));
      return res;
    } else {
      const res = dispatch(createReportAsync(data));
      return res;
    }
  };

  const [currentStageIdx, setCurrentStageIdx] = useState(0);

  const defaultStages = [
    {
      id: 1,
      title: t("Details"),
      description: t("report.details.description"),
      current: true,
      completed: false,
      active: true,
    },
    {
      id: 2,
      title: t("Items"),
      description: t("report.items.description"),
      current: false,
      completed: false,
      active: report?.report_type?.items ? true : false,
    },
    {
      id: 3,
      title: t("Activities"),
      description: t("report.activities.description"),
      current: false,
      completed: false,
      active: true,
    },
    {
      id: 4,
      title: t("Checklist"),
      description: t("report.checklist.description"),
      current: false,
      completed: false,
      active: report?.report_type?.checklist ? true : false,
    },
  ];
  const [stages, setStages] = useState(defaultStages);

  const activeStages = stages?.filter((stage) => stage.active === true);

  const [stage, setStage] = useState(defaultStages[0]);

  const [checklistTypeOptions, setChecklistTypeOptions] = useState([]);

  const activePageChecker = (stage) => {
    // Check if active page, else set next page
    if (stage.active) {
      const thisStageId = stage.id;
      const currentStageIdx = activeStages.findIndex(
        (stage) => stage.id === thisStageId
      );
      setStage((prevState) => stage);
      setCurrentStageIdx(currentStageIdx);
    } else {
      // create a loop that goes to next stage until it finds an active stage
      let nextStage = stage.id;
      while (nextStage < activeStages.length) {
        if (activeStages[nextStage].active) {
          setStage((prevState) => activeStages[nextStage - 1]);
          // idx of current "nextStage"
          const nextStageIdx = activeStages.findIndex(
            (stage) => stage.id === nextStage
          );
          setCurrentStageIdx(nextStage - 1);
          break;
        } else {
          nextStage++;
        }
      }
    }
  };

  useEffect(() => {
    if (report?.report_type) {
      updateActiveStages(report?.report_type);
    }
  }, [report]);

  const updateActiveStages = (reportType) => {
    if (reportType) {
      const updatedStages = stages.map((stage) => {
        if (stage.id === 2) {
          stage.active = reportType?.items ? true : false;
        }
        if (stage.id === 4) {
          stage.active = reportType?.checklist ? true : false;
        }
        return stage;
      });
      setStages(updatedStages);
    }
  };

  const handleNextPage = (formik) => {
    if (currentStageIdx !== activeStages?.length - 1) {
      const nextStage = activeStages[currentStageIdx + 1];
      activePageChecker(nextStage);
    }
    formik.submitForm();
  };

  useEffect(() => {
    async function fetchTypeOptions() {
      const fetchedOptions = await getChecklistTypeOptions();
      setChecklistTypeOptions(fetchedOptions);
    }

    if (open) {
      setCurrentStageIdx(0);
      setRefresh(false);
      fetchTypeOptions();
      setStage(defaultStages[0]);
      setItemState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      if (selected?.id) {
        getReport();

        updateActiveStages(report?.report_type);
      } else {
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      }
    } else {
      setItemState((prevState) => ({
        ...prevState,
        report: {},
      }));
    }
  }, [dispatch, open]);

  function onOtherInvoiceCustomerChange(e, formik) {
    if (!e.target.checked) {
      formik.setFieldValue("invoice_customer_id", null);
    }
  }

  const stageOne = (report, formik) => {
    return (
      <div className="grid grid-cols-6 gap-6 mt-10">
        <div className="col-span-3 sm:col-span-3">
          <PMSwitch
            name="invoice_to_project_customer"
            label={t("Invoice to Project Customer")}
            {...formik}
          />
        </div>
        <div className="col-span-3 sm:col-span-3">
          <PMSwitch
            name="other_invoice_customer"
            label={t("Other invoice customer")}
            onChange={(e) => onOtherInvoiceCustomerChange(e, formik)}
            {...formik}
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <PMField
            name="report_number"
            type="text"
            disabled
            label={t("Report Number")}
            {...formik}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <PMField
            name="order_date"
            type="date"
            label={"Ordredato"}
            {...formik}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <CustomerSelector
            label="Kunde"
            selected={report?.customer?.id ? report?.customer : customer}
            {...formik}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <ContactsSelector
            label="Kontaktperson"
            selected={report?.contact?.id ? report?.contact : contact}
            {...formik}
          />
        </div>

        {formik.values.other_invoice_customer && (
          <div className="col-span-6 sm:col-span-3">
            <CustomerSelector
              label="Faktura kunde"
              name="invoice_customer_id"
              selected={report?.invoice_customer}
              {...formik}
            />
          </div>
        )}

        <div className="col-span-6 sm:col-span-3">
          <ReportTypeSelector
            label="Rapport Type"
            selected={report?.report_type}
            {...formik}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <ProjectSelector
            label="Prosjekt"
            selected={report?.project?.id ? report?.project : project}
            {...formik}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <ProjectFolderSelector
            label="Prosjektmappe"
            projectId={formik.values?.project_id || project?.id}
            selected={report?.folder?.id ? report?.folder : null}
            {...formik}
          />
        </div>

        <div className="col-span-6 sm:col-span-6">
          <div className="flex justify-between">
            <label
              htmlFor="name"
              className="font-medium text-gray-700 flex flex-col"
            >
              <label className="text-md">Beskrivelse</label>
              <span className="text-sm italic">
                Husk at denne beskrivelsen er synlig for alle (også kunden)
              </span>
            </label>
            <div>
              <TemplateDialogButton fieldName="description" {...formik} />
            </div>
          </div>
          <QuillEditor name="description" rows={4} {...formik} />
        </div>

        <div className="col-span-6 sm:col-span-6">
          <div className="flex justify-between">
            <label
              htmlFor="name"
              className="font-medium text-gray-700 flex flex-col"
            >
              <label className="text-md">Intern beskrivelse</label>
              <span className="text-sm italic">
                Denne beskrivelsen er kun synlig for ansatte
              </span>
            </label>
            <div>
              <TemplateDialogButton
                fieldName="internal_description"
                {...formik}
              />
            </div>
          </div>

          <QuillEditor name="internal_description" rows={4} {...formik} />
        </div>

        <div className="col-span-6 sm:col-span-6">
          <div className="flex">
            <PMSwitch
              label="Bruk kundens adresse"
              name="useCustomerAddress"
              onChange={(e) => {
                formik.setFieldValue("delivery_address_id", null);
              }}
              {...formik}
            />
            <span className="ml-2">
              {report?.customer?.postal_address && (
                <>({report?.customer?.postal_address.displayTitle})</>
              )}
            </span>
          </div>
        </div>

        {formik.values.useCustomerAddress && report?.customer?.id ? null : (
          <div className="col-span-6 sm:col-span-3 mb-20">
            <DeliveryAddressSelector
              label="Oppdragsadresse"
              module="CUSTOMER"
              module_param={formik.values.customer_id}
              fields={
                fields || {
                  name: report?.customer?.name,
                }
              }
              selected={report?.delivery_address}
              {...formik}
            />
          </div>
        )}

        <div className="col-span-6 sm:col-span-3 mb-20">
          <SystemSelector
            label="Anlegg"
            module="CUSTOMER"
            module_param={
              report?.customer?.id
                ? report?.customer?.id
                : formik.values.customer_id
            }
            fields={fields}
            selected={report?.system}
            {...formik}
          />
        </div>
      </div>
    );
  };

  const stageTwo = (report, formik) => {
    return (
      <div className="grid grid-cols-6 gap-6 mt-10">
        <div className="col-span-6 sm:col-span-6 overflow-auto">
          <OrderItems
            group={false}
            sales_price={true}
            purchase_price={false}
            discount={true}
            surcharge={false}
            drag={false}
            quantity_picked={false}
            quantity={true}
            {...formik}
          />
        </div>
      </div>
    );
  };

  const stageThree = (report, formik) => {
    return (
      <div className="grid grid-cols-6 gap-6 mt-10">
        <div className="col-span-6 sm:col-span-6">
          <ActivitiesTable
            name="activity_items"
            number={false}
            invoiceable={false}
            inactive={false}
            drag={false}
            project={report?.project}
            title={false}
            module={"report"}
            budget_fee={true}
            budget_fee_rate={true}
            budget_hours={true}
            {...formik}
          />
        </div>
      </div>
    );
  };

  const [selectedChecklistType, setSelectedChecklistType] = useState(null);

  const submitCreateChecklist = async (selectedChecklistType) => {
    await createReportChecklist({
      report: {
        id: report?.id,
        checklist_type_id: selectedChecklistType,
      },
      fields: "id,checklist",
    })
      .then((res) => {
        if (res.data.id && res.data.checklist) {
          // Update only checklist
          setItemState((prevState) => ({
            ...prevState,
            report: {
              ...prevState.report,
              checklist: res.data.checklist,
            },
          }));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const deleteChecklist = async () => {
    await deleteReportChecklist({
      id: report?.id,
    })
      .then((res) => {
        if (res.status === 200) {
          setItemState((prevState) => ({
            ...prevState,
            report: {
              ...prevState.report,
              checklist: null,
            },
          }));
          setDeleteChecklistPrompt(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const [deleteChecklistPrompt, setDeleteChecklistPrompt] = useState(false);

  const canDeleteChecklist = useUserHasAccess({
    role: "report",
    action: "delete_checklist",
  });

  const stageFour = (report, formik) => {
    if (report?.checklist?.id) {
      return (
        <div className="grid grid-cols-6 gap-6 mt-10">
          <div className="col-span-6 sm:col-span-6">
            {canDeleteChecklist && (
              <>
                <div className="flex justify-end mb-10">
                  <PMButton
                    type="button"
                    onClick={() => setDeleteChecklistPrompt(true)}
                    className="max-w-[150px] bg-red-400 right-0"
                    tooltip="Sletter sjekklisten"
                  >
                    Slett sjekkliste
                  </PMButton>
                </div>
                <SimplePrompt
                  title="Sletter sjekklisten"
                  description="Dette kan ikke reverseres. Er du sikker på at du vil slette sjekklisten?"
                  onAccept={() => deleteChecklist()}
                  open={deleteChecklistPrompt}
                  onClose={() => setDeleteChecklistPrompt(false)}
                />
              </>
            )}

            <ChecklistItemLines
              checklist={report?.checklist}
              items={report?.checklist?.items}
              editable={true}
              isLoading={isLoading}
              drag={true}
              addRow={true}
              deleteRow={true}
              deviation={false}
              disabled={
                report?.checklist?.is_items_editable !== undefined &&
                !report?.checklist?.is_items_editable
              }
              type="edit"
              parentCallback={(data) =>
                formik.setFieldValue("checklist.items", data)
              }
            />
          </div>
        </div>
      );
    }

    return (
      <div className="grid grid-cols-6 gap-6 mt-10">
        <div className="col-span-6 sm:col-span-6">
          <>
            <div className="py-5 px-5 rounded-md shadow-xl mb-20">
              <PMNotice
                title="Sjekkliste mangler"
                description="Rapporten er ikke knyttet til sjekkliste"
              />

              <div className="col-span-6 sm:col-span-3">
                <PMCombobox
                  name="checklist_type_id"
                  label={"Sjekkliste Type"}
                  options={checklistTypeOptions}
                  onChange={(data) => {
                    setSelectedChecklistType(data);
                    console.log(data);
                  }}
                />
              </div>
              <div className="flex justify-between">
                {selectedChecklistType !== null && (
                  <PMButton
                    type="button"
                    onClick={() => submitCreateChecklist(selectedChecklistType)}
                    className="mt-10 w-max"
                    tooltip="Oppretter sjekkliste bassert på sjekkliste type"
                  >
                    Last inn
                  </PMButton>
                )}

                <PMButton
                  onClick={() => submitCreateChecklist()}
                  type="button"
                  className="mt-10"
                  tooltip="Oppretter helt ny og tom sjekkliste"
                >
                  Opprett ny
                </PMButton>
              </div>
            </div>
          </>
        </div>
      </div>
    );
  };

  const [refresh, setRefresh] = useState(false);

  const handleFinish = (formik) => {
    formik.submitForm();
    setRefresh(true);
  };

  const handleOnRefresh = (data) => {
    setItemState((prevState) => ({
      ...prevState,
      report: data,
    }));

    setTimeout(() => {
      setItemState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }, 500);

    if (refresh) {
      if (props.onRefresh) {
        props.onRefresh(data);
      }

      onClose(true);

      if (data?.id && redirect) {
        setTimeout(() => {
          // await ID, then navigate
          navigate(`/dashboard/service/reports/${data?.id}`);
        }, 200);
      }
    }
  };

  const handleStageChange = (stage) => {
    setItemState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    setTimeout(() => {
      setItemState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }, 250);

    activePageChecker(stage);
  };

  return (
    <PMDialog
      title={report?.id ? t("report.update") : t("report.create")}
      open={open}
      onClose={onClose}
      size="5xl"
      maxWidth="7xl"
    >
      {!isLoading && (
        <div>
          {stages && (
            <PMSteps
              steps={activeStages}
              selected={stage}
              onChange={handleStageChange}
            />
          )}
        </div>
      )}

      {isLoading ? (
        <ResourceLoading />
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            id: report?.id ? report?.id : null,
            customer_id: report?.customer
              ? report?.customer.id
              : customer?.id
              ? customer?.id
              : null,
            contact_id: report?.contact
              ? report?.contact.id
              : contact?.id
              ? contact?.id
              : null,
            report_type: report?.report_type
              ? report?.report_type.id
              : report_type?.id
              ? report_type?.id
              : null,
            project_id: report?.project
              ? report?.project.id
              : project?.id
              ? project?.id
              : null,
            order_date: report?.order_date
              ? report?.order_date
              : new Date().toISOString().substring(0, 10),
            description: report?.description
              ? report?.description
              : fields?.description
              ? fields?.description
              : null,
            folder_id: report?.folder?.id || null,
            checklist_type_id: report?.checklist?.checklist_type?.id || null,
            items: report?.items,
            invoice_to_project_customer: report?.invoice_to_project_customer,
            activity_items: report?.activity_items,
            report_number: report?.report_number,
            internal_description: report?.internal_description,
            useCustomerAddress: report?.useCustomerAddress,
            delivery_address_id: report?.delivery_address?.id,
            other_invoice_customer: report?.invoice_customer?.id ? true : false,
            submit: null,
          }}
          validationSchema={Yup.object({
            customer_id: Yup.string().required(t("customer.required")),
            report_type: Yup.string().required(t("report.type.required")),
            description: Yup.string(),
          })}
          onSubmit={(values, helpers) => {
            try {
              dispatchWithToast(
                createOrUpdateDispatch(values),
                handleOnRefresh
              );
              helpers.setStatus({ success: true });
              helpers.setSubmitting(false);
            } catch (err) {
              toast.error("Something went wrong...");
              console.error(err);
              helpers.setStatus({ success: false });
              helpers.setErrors({ submit: err.message });
              helpers.setSubmitting(false);
            }
          }}
        >
          {(formik) => (
            <Form>
              {stage?.id === 1 && stageOne(report, formik)}
              {stage?.id === 2 && stageTwo(report, formik)}
              {stage?.id === 3 && stageThree(report, formik)}
              {stage?.id === 4 && stageFour(report, formik)}

              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-3 sm:gap-3 justify-between">
                <div className="w-full col-span-2">
                  <button
                    type="button"
                    className="justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    onClick={onClose}
                  >
                    {"Steng"}{" "}
                  </button>
                </div>

                <div className="flex w-full col-span-1 right align-right gap-4 justify-end">
                  <button
                    type="button"
                    className="align-right justify-center rounded-md border border-transparent bg-yellow-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    onClick={() => handleFinish(formik)}
                  >
                    {report?.id ? "Oppdater" : "Opprett"}
                    {" & Steng"}
                  </button>
                  {currentStageIdx !== activeStages?.length - 1 && (
                    <button
                      type="button"
                      className="align-right justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                      onClick={() => handleNextPage(formik)}
                    >
                      Neste
                    </button>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </PMDialog>
  );
}

ServiceReportEditModal.defaultProps = {
  redirect: true,
};
