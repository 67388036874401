import toast from "react-hot-toast";
import { useState } from "react";
import CustomerDialog from "../../customers/dialog";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import ServiceReportEditModal from "./service-report-edit-modal";
import CustomerSystemDialog from "../../customers/systems/system-dialog";
import { useDispatch } from "react-redux";
import { updateReportAsync } from "../../../../slices/service/reports";
import { mapErrors } from "../../../Utils";
import { useTranslation } from "react-i18next";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { ProjectTag } from "../../../utils/moduleTags/projectTag";
import { CustomerTag } from "../../../utils/moduleTags/customerTag";
import { CustomerSystemTag } from "../../../utils/moduleTags/customerSystemTag";

export const ReportInfo = (props) => {
  const [customerDialogOpen, setCustomerDialogOpen] = useState(false);
  const [customerSystemDialogOpen, setCustomerSystemDialogOpen] =
    useState(false);
  const { report, isLoading } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleAttachSystem = (data) => {
    if (!report.system?.id) {
      alert("Attaching system");
      setCustomerSystemDialogOpen(false);
      const payload = {
        id: report.id,
        system_id: data.value,
      };

      toast.promise(dispatch(updateReportAsync(payload)), {
        loading: t("Saving...."),
        success: (data) => {
          if (data.status === 500) throw new Error("Server error");
          if (data.payload.errors)
            throw new Error(mapErrors(data.payload.errors));
          props.onRefresh(data.payload);
          return t("Data was saved!");
        },
        error: (error) => {
          return t("Something went wrong. Could not save data!");
        },
      });
    }
  };

  if (isLoading) return <ResourceLoading />;

  return (
    <>
      {/* Description list*/}
      <section
        className="lg:max-w[7xl]"
        aria-labelledby="applicant-information-title"
      >
        <div className="bg-white shadow sm:rounded-lg">
          <div className="flex items-center px-4 py-5 sm:px-6 w-full">
            <div className="block w-[60%]">
              <h2
                id="applicant-information-title"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Detaljer
              </h2>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Informasjon om rapporten
              </p>
            </div>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <CustomerTag customer={report?.customer} />
              </div>
              <div className="sm:col-span-1">
                <ProjectTag project={report?.project} folder={report?.folder} />
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Type</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {report?.report_type?.title}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <CustomerSystemTag
                  selected={report?.system}
                  customer={report?.customer}
                  module="report"
                  moduleParam={report?.id}
                />
              </div>

              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Oppdragsbeskrivelse
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <div
                    dangerouslySetInnerHTML={{ __html: report?.description }}
                  />
                </dd>
              </div>

              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Intern beskrivelse
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: report?.internal_description,
                    }}
                  />
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </section>

      <CustomerDialog
        open={customerDialogOpen}
        onClose={() => setCustomerDialogOpen(false)}
        readOnly={true}
        // selected={{ report.customer }}
        // Only for testing
        selected={report?.customer}
        onRefresh={() => props.onRefresh()}
      />

      <CustomerSystemDialog
        open={customerSystemDialogOpen}
        onClose={() => setCustomerSystemDialogOpen(false)}
        readOnly={true}
        selectedSystem={report?.system_id}
        customer={report.customer}
        selected={report?.customer}
        onAttachSystem={(data) => handleAttachSystem(data)}
        module="report"
        moduleParam={report?.id}
      />
    </>
  );
};
