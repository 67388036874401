import React, { useEffect, useRef } from "react";
import UploadService from "./UploadService";
import { ResourceLoading } from "../../utils/ResourceLoading";
import { TWclassNames } from "../../utils/Div";
import { CameraIcon } from "@heroicons/react/24/outline";

export const UploaderV2 = (props) => {
  const {
    module_param,
    size,
    disabled,
    version,
    withoutModuleParam,
    required,
  } = props;

  console.log("req", required);

  const smallVariants = ["xs", "sm", "md"];

  const allowedFileTypes =
    props.allowedFileTypes ||
    "image/png, image/jpeg, image/jpg, image/gif, image/svg, image/svg+xml, image/webp, application/pdf";

  const [state, setState] = React.useState({
    isLoading: false,
    selectedFiles: [],
    documents: [],
    document: [],
    progressInfos: [],
    fileInfos: [],
    selectedDocument: false,
    message: [],
    shouldUpload: false,
  });

  const { selectedFiles } = state;

  const isLoading = props.isLoading || state.isLoading;

  const fileInput = useRef(null);

  function clearForm() {
    setState((prevState) => ({
      ...prevState,
      selectedFiles: [],
    }));
    // fileInput.current.value = "";
  }

  function selectFiles(event) {
    setState((prevState) => ({
      ...prevState,
      progressInfos: [{ percentage: 0 }],
      selectedFiles: event.target.files,
    }));
  }

  useEffect(() => {
    if (selectedFiles.length > 0) {
      props.onLoading(true);
      uploadFiles();
    }
  }, [state.selectedFiles]);

  function upload(idx, file) {
    let _progressInfos = [...state.progressInfos];

    UploadService.upload(file, props.module, module_param, (event) => {
      // _progressInfos[idx].percentage = Math.round(
      //   (100 * event.loaded) / event.total
      // );

      let updatedProgressInfos = [...state.progressInfos];
      updatedProgressInfos[idx] = {
        ...updatedProgressInfos[idx],
        percentage: Math.round((100 * event.loaded) / event.total),
      };

      setState((prevState) => ({
        ...prevState,
        // _progressInfos,
        // progressInfos: _progressInfos,
        progressInfos: updatedProgressInfos,
      }));
    })
      .then((response) => {
        props.parentCallback(response.data);

        setState((prevState) => ({
          ...prevState,
          message: [
            ...prevState.message,
            "Uploaded the file successfully: " + file.name,
          ],
          fileInfos: response.data,
        }));
      })

      .catch(() => {
        // _progressInfos[idx].percentage = 0;

        let updatedProgressInfos = [...state.progressInfos];
        updatedProgressInfos[idx] = {
          ...updatedProgressInfos[idx],
          percentage: 0,
        };

        setState((prevState) => ({
          ...prevState,
          // progressInfos: _progressInfos,
          progressInfos: updatedProgressInfos,
          message: [
            ...prevState.message,
            "Could not upload the file: " + file.name,
          ],
        }));
      });
  }

  // function uploadFiles() {
  //   setState((prevState) => ({
  //     ...prevState,
  //     isLoading: true,
  //   }));

  //   setTimeout(() => {
  //     const { selectedFiles } = state;
  //     let _progressInfos = [];

  //     for (let i = 0; i < selectedFiles?.length; i++) {
  //       _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
  //     }

  //     setState((prevState) => ({
  //       ...prevState,
  //       progressInfos: _progressInfos,
  //       message: [],
  //       isLoading: false,
  //     }));

  //     for (let i = 0; i < selectedFiles.length; i++) {
  //       upload(i, selectedFiles[i]);
  //     }
  //   }, 500);
  // }

  function uploadFiles() {
    const { selectedFiles } = state;
    let _progressInfos = [];

    for (let i = 0; i < selectedFiles?.length; i++) {
      _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
    }

    setState((prevState) => ({
      ...prevState,
      progressInfos: _progressInfos,
      message: [],
      isLoading: true,
      shouldUpload: true,
    }));
  }

  useEffect(() => {
    if (state.shouldUpload) {
      const { selectedFiles } = state;
      for (let i = 0; i < selectedFiles.length; i++) {
        upload(i, selectedFiles[i]);
      }
      // Reset the flag
      setState((prevState) => ({
        ...prevState,
        shouldUpload: false,
        isLoading: false,
      }));
    }
  }, [state.shouldUpload]);

  // generate a random key

  function uploader() {
    const random = Math.random().toString(36).substring(2, 15);
    return (
      <div className="">
        <div className="flex justify-center rounded-md border-2 border-dashed border-gray-300 px-4 pt-2 pb-2 h-full w-full">
          {isLoading ? (
            <ResourceLoading
              size={"other"}
              baseClass="h-5 w-10 mx-auto"
              loaderClass="h-5 w-5 mx-auto"
            />
          ) : (
            <div className="space-y-1 text-center">
              {!smallVariants.includes(size) && (
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}

              <div className="flex text-sm text-gray-600">
                <label
                  htmlFor={random + "-file-upload"}
                  className={TWclassNames(
                    disabled ? "cursor-not-allowed" : "bg-white",
                    "relative w-full h-full cursor-pointer rounded-md  font-medium text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 hover:text-blue-500"
                  )}
                >
                  <span>Upload a file</span>
                  <input
                    accept={allowedFileTypes}
                    disabled={disabled}
                    id={random + "-file-upload"}
                    name={random + "-file-upload"}
                    type="file"
                    className="sr-only"
                    onChange={selectFiles}
                    multiple={props.multiple}
                  />
                </label>
                {/* <p className="pl-1">or drag and drop</p> */}
              </div>
              {!smallVariants.includes(size) && (
                <p className="text-xs text-gray-500">
                  PNG, JPG, GIF up to 10MB
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  function uploaderV2() {
    const random = Math.random().toString(36).substring(2, 15);

    return (
      <div className="text-center rounded-md border-2 required:bg-red-400 bg-blue-400 hover:bg-blue-600 border-gray-300 px-0 sm:px-4 py-2 sm:py-2">
        {isLoading ? (
          <ResourceLoading
            size={"other"}
            baseClass="h-5 w-10 mx-auto"
            loaderClass="h-5 w-5 mx-auto"
          />
        ) : (
          <label
            htmlFor={random + "-file-upload"}
            className={TWclassNames(
              disabled ? "cursor-not-allowed" : "bg-white",
              "relative cursor-pointer rounded-md  font-medium text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-400 focus-within:ring-offset-2"
            )}
          >
            <div className="w-full h-full flex flex-row items-center justify-center">
              <CameraIcon className="h-5 w-6 mr-2 text-white" />
              <div className="flex-row">
                <span>Last opp dokument/ bilde</span>
              </div>
            </div>
            <input
              accept={allowedFileTypes}
              disabled={disabled}
              multiple={props.multiple}
              id={random + "-file-upload"}
              name={random + "-file-upload"}
              type="file"
              className="sr-only"
              onChange={selectFiles}
            />
          </label>
        )}
      </div>
    );
  }

  function uploaderV3() {
    const random = Math.random().toString(36).substring(2, 15);

    return (
      <div
        className={TWclassNames(
          required ? "border-2 border-red-400" : "",
          "text-center bg-blue-400  hover:bg-blue-600 border-gray-300 px-5 py-5"
        )}
      >
        {isLoading ? (
          <ResourceLoading
            size={"other"}
            baseClass="h-5 w-10 mx-auto"
            loaderClass="h-5 w-5 mx-auto"
          />
        ) : (
          <label
            htmlFor={random + "-file-upload"}
            className={TWclassNames(
              disabled ? "cursor-not-allowed" : "bg-white",
              "relative cursor-pointer rounded-md  font-medium text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-400 focus-within:ring-offset-2"
            )}
          >
            <div className="w-full h-full flex flex-row items-center justify-center">
              <CameraIcon className="h-5 w-6 mr-2 text-white" />
              <div className="flex-row">
                <span>Last opp dokument/ bilde</span>
              </div>
            </div>
            <input
              accept={allowedFileTypes}
              disabled={disabled}
              multiple={props.multiple}
              id={random + "-file-upload"}
              name={random + "-file-upload"}
              type="file"
              className="sr-only"
              onChange={selectFiles}
            />
          </label>
        )}
      </div>
    );
  }

  function uploaderV4() {
    const random = Math.random().toString(36).substring(2, 15);

    return (
      <div className="text-center">
        {isLoading ? (
          <ResourceLoading
            size={"other"}
            baseClass="h-5 w-10 mx-auto"
            loaderClass="h-5 w-5 mx-auto"
          />
        ) : (
          <label
            htmlFor={random + "-file-upload"}
            className={TWclassNames(
              disabled ? "cursor-not-allowed" : "",
              "text-blue-400",
              "relative cursor-pointer rounded-md  font-medium"
            )}
          >
            <div className="w-full h-full flex flex-row items-center">
              <CameraIcon className="h-5 w-6 mr-2" />
              <div className="grid col-span-1 align-left">
                <span className="text-left">Last opp</span>
                <span className="text-sm font-light italic text-gray-400 align-left left-0">
                  Dokumenter & bilder
                </span>
              </div>
            </div>
            <input
              accept={allowedFileTypes}
              disabled={disabled}
              multiple={props.multiple}
              id={random + "-file-upload"}
              name={random + "-file-upload"}
              type="file"
              className="sr-only"
              onChange={selectFiles}
            />
          </label>
        )}
      </div>
    );
  }

  if (!module && !module_param && !withoutModuleParam) {
    return <div>Module, Module ID &/ or Company ID is missing.</div>;
  }

  if (version === 1) return uploader();
  if (version === 2) return uploaderV2();
  if (version === 3) return uploaderV3();
  if (version === 4) return uploaderV4();
};

UploaderV2.defaultProps = {
  key: null,
  size: "base",
  version: 1,
  onLoading: () => {},
  withoutModuleParam: false,
};
