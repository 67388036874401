import produce from "immer";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchQualitys,
  fetchQuality,
  updateQuality,
  createQuality,
  deleteQuality,
} from "../../api/quality/quality";

const initialState = {
  isLoading: false,
  error: false,
  errors: null,
  data: [],
  totalCount: 0,
  currentCount: 0,
  newCount: 0,
};

export const initialController = {
  filters: [],
  page: 0,
  query: "",
  sort: "desc",
  sortBy: "created_at",
  view: "all",
};

export const deleteQualityAsync = createAsyncThunk(
  "chapters/deleteQualityAsync",
  async (payload) => {
    let response = await deleteQuality(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const createQualityAsync = createAsyncThunk(
  "chapters/createQualityAsync",
  async (payload) => {
    let response = await createQuality(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);

export const updateQualityAsync = createAsyncThunk(
  "chapters/updateQualityAsync",
  async (payload) => {
    let response = await updateQuality(payload);

    // If errors, reject and return response
    if (response.error) return false;

    // If all good, return response.
    return response;
  }
);
export const getQualitysAsync = createAsyncThunk(
  "chapters/getQualitysAsync",
  async (controller = initialController, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchQualitys(controller);
    // If errors, reject and return response
    if (response.response?.status?.match(/404|500|401/))
      return rejectWithValue(true);

    // If all good, return response.
    return response;
  }
);

export const getQualityAsync = createAsyncThunk(
  "chapters/getQualityAsync",
  async (id, { rejectWithValue }) => {
    // if (! controller) return false;
    let response = await fetchQuality(id);

    // If errors, reject and return response
    if (response.error) return rejectWithValue(response);

    // If all good, return response.
    return response;
  }
);

const qualitySlice = createSlice({
  name: "chapters",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getQualitysAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getQualitysAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.totalCount = action.payload.totalCount;
        state.currentCount = action.payload.currentCount;
        state.newestQualitysCount = action.payload.newCount;
      })
      .addCase(getQualitysAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = [
          "Something went wrong when trying to fetch variable types....is controller missing?",
        ];
      })
      .addCase(updateQualityAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateQualityAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          const index = draftState.data.findIndex(
            (type) => type.id === action.payload.id
          );
          draftState.data[index] = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(updateQualityAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update chapter"];
      })
      .addCase(createQualityAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createQualityAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data.push(action.payload);
          draftState.isLoading = false;
        });
      })
      .addCase(createQualityAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update chapter"];
      })
      .addCase(deleteQualityAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteQualityAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.data = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(deleteQualityAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update chapter"];
      })
      .addCase(getQualityAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getQualityAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.chapter = action.payload;
          draftState.isLoading = false;
        });
      })
      .addCase(getQualityAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errors = ["Something went wrong when trying to update chapter."];
      });
  },
});

// export const {} = customerSlice.actions;

export const { reducer } = qualitySlice;

export default qualitySlice;
