/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, ClockIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  LinkIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";
import { toast } from "react-hot-toast";
import { getProductsAsync } from "../../../slices/products";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { dispatchWithToast, formatter } from "../../Utils";
import imgPlaceholder from "../../../utils/img-placeholder.png";
import { useNavigate } from "react-router-dom";
import { PMField } from "../../utils/PMField";
import { debounce } from "lodash";
import { ResourceEmpty } from "../../utils/ResourceEmpty";
import { PMDialog } from "../../utils/PMDialog";
import { Form, Formik } from "formik";
import {
  addReportItemAsync,
  updateReportAsync,
} from "../../../slices/service/reports";
import * as Yup from "yup";
export default function ProductSlideover(props) {
  const { open, onClose, productId, view, module, moduleParam } = props;
  const dispatch = useDispatch();
  const [controller, setController] = useState({
    filters: [{ name: "spareparts", value: productId }],
    page: 1,
    per_page: 40,
    query: "",
    sort: "asc",
    sortBy: "created_at",
    view: props.view,
    product_id: productId,
  });

  const productState = useSelector((store) => {
    return store.products;
  });

  useEffect(() => {
    setController({
      ...controller,
      filters: [{ name: "spareparts", value: productId }],
    });
  }, [productId]);

  useEffect(() => {
    // debounce while waiting for the user to stop typing
    const debounced = debounce(() => {
      if (open) {
        dispatch(getProductsAsync(controller));
      }
    }, 1000);

    debounced();

    return () => {
      debounced.cancel();
    };
  }, [open, controller]);

  const onQueryChange = (e) => {
    setController({ ...controller, query: e.target.value });
  };

  const { data, isLoading } = productState;

  const navigate = useNavigate();

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [addReportItemsDialogOpen, setAddReportItemsDialogOpen] =
    useState(false);

  const addToReportItems = (product) => {
    setSelectedProduct(product);
    setAddReportItemsDialogOpen(true);
  };

  const products = () => (
    <ul
      role="list"
      className="divide-y divide-gray-200 border-t border-b border-gray-200"
    >
      {data.length === 0 ? (
        <ResourceEmpty />
      ) : (
        data?.map((product) => (
          <li key={product.id} className="flex py-6">
            <div className="flex-shrink-0">
              <img
                src={
                  product?.main_image?.src
                    ? product?.main_image?.src
                    : imgPlaceholder
                }
                alt={product?.main_image?.title}
                className="h-24 w-24 rounded-md object-cover object-center sm:h-32 sm:w-32"
              />
            </div>

            <div className="ml-4 flex flex-1 flex-col sm:ml-6">
              <div>
                <div className="flex justify-between">
                  <h4 className="text-sm">
                    <a
                      href={product.href}
                      className="font-medium text-gray-700 hover:text-gray-800"
                    >
                      {product.title}
                    </a>
                  </h4>
                  <p className="ml-4 text-sm font-medium text-gray-900">
                    {formatter.format(product.sales_price)}
                  </p>
                </div>
                <p className="mt-1 text-sm text-gray-500">
                  {product.item_number}
                </p>
                <p className="mt-1 text-sm text-gray-500">
                  {product.description}
                </p>
              </div>

              <div className="mt-4 flex flex-1 items-end justify-between">
                <p className="flex items-center space-x-2 text-sm text-gray-700">
                  {product.stock > 0 ? (
                    <CheckIcon
                      className="h-5 w-5 flex-shrink-0 text-green-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <div className="flex items-center">
                      Ikke på lager
                      <ClockIcon
                        className="ml-2 h-5 w-5 flex-shrink-0 text-gray-300"
                        aria-hidden="true"
                      />
                    </div>
                  )}

                  {/* <span>{product.inStock ? 'In stock' : `Will ship in ${product.leadTime}`}</span> */}
                </p>

                <div className="ml-4">
                  <button
                    onClick={() =>
                      navigate("/dashboard/products/" + product?.id)
                    }
                    type="button"
                    className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    <span>Se produkt</span>
                  </button>
                </div>
              </div>
              <div className="mt-4 flex flex-1 items-end justify-between">
                <div>
                  <button
                    onClick={() => addToReportItems(product)}
                    type="button"
                    className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    <span>Legg til som vareforbruk</span>
                  </button>
                </div>
              </div>
            </div>
          </li>
        ))
      )}
    </ul>
  );

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">
                              Spareparts
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={onClose}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-indigo-300">
                              Viser reservedeler for valgt produkt.
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-200 px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              <div className="py-4">
                                <PMField
                                  name="query"
                                  type="text"
                                  label="Søk"
                                  onChange={(e) => onQueryChange(e)}
                                />
                              </div>
                              <div>{products()}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-end px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={onClose}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <PMDialog
        open={addReportItemsDialogOpen}
        onClose={() => setAddReportItemsDialogOpen(false)}
        title="Legg til som vareforbruk"
      >
        <span>
          {selectedProduct?.item_number + " " + selectedProduct?.title}
        </span>
        <Formik
          enableReinitialize
          initialValues={{
            id: moduleParam,
            product_id: selectedProduct?.id,
            quantity: 1,
            submit: null,
          }}
          validationSchema={Yup.object({})}
          onSubmit={(values, helpers) => {
            try {
              dispatchWithToast(
                dispatch(addReportItemAsync(values)),
                null,
                () => setAddReportItemsDialogOpen(false)
              );
              helpers.setStatus({ success: true });
              helpers.setSubmitting(false);
            } catch (err) {
              toast.error("Something went wrong...");
              console.error(err);
              helpers.setStatus({ success: false });
              helpers.setErrors({ submit: err.message });
              helpers.setSubmitting(false);
            }
          }}
        >
          {(formik) => (
            <Form>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-3 sm:gap-3 justify-between">
                <div className="col-span-3">
                  <PMField
                    name="quantity"
                    type="number"
                    label="Antall"
                    {...formik}
                  />
                </div>
                <div className="w-full col-span-2">
                  <button
                    type="button"
                    className="justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    onClick={onClose}
                  >
                    {"Steng"}{" "}
                  </button>
                </div>

                <div className="flex w-full col-span-1 right align-right gap-4 justify-end">
                  <button
                    type="submit"
                    className="align-right justify-center rounded-md border border-transparent bg-yellow-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                  >
                    Legg til
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </PMDialog>
    </>
  );
}

ProductSlideover.defaultProps = {
  open: false,
  onClose: () => toast.error("Function is missing"),
};
