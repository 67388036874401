import {
  configureStore,
  ThunkAction,
  Action,
  PreloadedState,
  StateFromReducersMapObject,
} from "@reduxjs/toolkit";
import sessionReducer from "./slices/session";

import { reducer as companyReducer } from "./slices/company";
import { reducer as customerReducer } from "./slices/customer";
import { reducer as customerSystemReducer } from "./slices/customer/system";
import { reducer as reportReducer } from "./slices/service/reports";
import { reducer as serviceAgreementReducer } from "./slices/service/agreements";
import { reducer as reportTypesReducer } from "./slices/service/report-types";
import { reducer as agreementTypesReducer } from "./slices/service/agreement-types";
import { reducer as variableTypesReducer } from "./slices/service/variable-types";
import { reducer as productsReducer } from "./slices/products";
import { reducer as inventoryStoragesReducer } from "./slices/inventory/storage";
import { reducer as inventoryCountsReducer } from "./slices/inventory/count";
import { reducer as inventoryCountItemsReducer } from "./slices/inventory/count_item";
import { reducer as productBrandsReducer } from "./slices/products/brands";
import { reducer as orderReducer } from "./slices/order";
import { reducer as caseReducer } from "./slices/case";
import { reducer as caseTypeReducer } from "./slices/case/type";
import { reducer as deviationReducer } from "./slices/quality/deviation";
import { reducer as checklistReducer } from "./slices/quality/checklist";
import { reducer as checklistTypeReducer } from "./slices/quality/checklist-type";
import { reducer as qualityChapterReducer } from "./slices/quality/quality_chapter";
import { reducer as contactReducer } from "./slices/customer/contact";
import { reducer as userReducer } from "./slices/user";
import { reducer as departmentReducer } from "./slices/department";
import { reducer as projectReducer } from "./slices/project";
import { reducer as projectFolderReducer } from "./slices/project/folder";
import { reducer as unitReducer } from "./slices/unit";
import { reducer as activityReducer } from "./slices/activity";
import { reducer as activityItemReducer } from "./slices/activity_item";
import { reducer as hourReducer } from "./slices/hour";
import { reducer as vehicleReducer } from "./slices/vehicle";
import { reducer as drivingTripReducer } from "./slices/driving/trip";
import { reducer as drivingDriverReducer } from "./slices/driving/driver";
import { reducer as documentReducer } from "./slices/document";
import { reducer as ResourceHubFaqCategoriesReducer } from "./slices/resource-hub/faq/category";
import { reducer as ResourceHubFaqPostsReducer } from "./slices/resource-hub/faq/post";

const reducer = {
  session: sessionReducer,
  users: userReducer,
  company: companyReducer,
  customers: customerReducer,
  customer_systems: customerSystemReducer,
  reports: reportReducer,
  report_types: reportTypesReducer,
  variable_types: variableTypesReducer,
  agreement_types: agreementTypesReducer,
  products: productsReducer,
  product_brands: productBrandsReducer,
  inventory_storages: inventoryStoragesReducer,
  inventory_counts: inventoryCountsReducer,
  inventory_count_items: inventoryCountItemsReducer,
  orders: orderReducer,
  cases: caseReducer,
  case_types: caseTypeReducer,
  deviations: deviationReducer,
  checklists: checklistReducer,
  checklist_types: checklistTypeReducer,
  quality_chapters: qualityChapterReducer,
  contacts: contactReducer,
  departments: departmentReducer,
  projects: projectReducer,
  projectFolders: projectFolderReducer,
  units: unitReducer,
  activities: activityReducer,
  activity_items: activityItemReducer,
  hours: hourReducer,
  vehicles: vehicleReducer,
  drivingTrips: drivingTripReducer,
  drivers: drivingDriverReducer,
  service_agreements: serviceAgreementReducer,
  documents: documentReducer,
  resource_hub_faq_categories: ResourceHubFaqCategoriesReducer,
  resource_hub_faq_posts: ResourceHubFaqPostsReducer,
};

const loadStateFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveStateToLocalStorage = (state: any) => {
  try {
    const savedStates = {
      session: state.session,
    };
    const serializedState = JSON.stringify(savedStates);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    console.error(err);
  }
};

export type RootState = StateFromReducersMapObject<typeof reducer>;

export function initStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer,
    preloadedState,
  });
}

export const store = initStore(loadStateFromLocalStorage());

store.subscribe(() => saveStateToLocalStorage(store.getState()));

type Store = ReturnType<typeof initStore>;
type RootState__ = ReturnType<Store["getState"]>; //alternate way
export type AppDispatch = Store["dispatch"];
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
