import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchQualityChapter } from "../../../../../api/quality/quality_chapter";
import { axiosWithToast, dispatchWithToast } from "../../../../Utils";
import {
  createQualityChapterAsync,
  updateQualityChapterAsync,
} from "../../../../../slices/quality/quality_chapter";
import { Form, Formik } from "formik";
import toast from "react-hot-toast";
import { PMField } from "../../../../utils/PMField";
import { LoadingButton } from "@mui/lab";
import {
  ArrowRightIcon,
  CheckBadgeIcon,
  PencilIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import * as Yup from "yup";
import { PMDialog } from "../../../../utils/PMDialog";
import { set } from "lodash";

export default function QualityChapterDialog(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [itemState, setItemState] = useState({
    isLoading: true,
    error: false,
    errors: [],
    chapter: {},
  });

  // Deconstructing props
  const { open, onClose, selected, onRefresh, redirect } = props;

  // Deconstruct itemState (Store)
  const { chapter, isLoading, error, errors } = itemState;

  const [readOnly, setReadOnly] = useState(props.readOnly);

  const getChapter = async () => {
    axiosWithToast(
      fetchQualityChapter(selected?.id),
      () => {},
      () => {
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      },
      (data) => {
        setItemState((prevState) => ({
          ...prevState,
          chapter: data,
          isLoading: false,
        }));
      },
      "Loadng chapter..."
    );
  };

  useEffect(() => {
    if (open) {
      if (selected?.id) {
        getChapter();
      } else {
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          chapter: {},
          error: false,
          errors: [],
        }));

        setReadOnly(false);
      }
    }
  }, [dispatch, open, selected]);

  const createOrUpdateDispatch = (data) => {
    if (chapter?.id) {
      const res = dispatch(updateQualityChapterAsync(data));
      return res;
    } else {
      const res = dispatch(createQualityChapterAsync(data));
      return res;
    }
  };

  const redirectToCustomer = (data) => {
    if (data?.id) {
      navigate(`/dashboard/chapters/` + data?.id);
    }
  };

  const ChapterForm = () => {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          id: chapter?.id,
          chapter_number: chapter?.chapter_number,
          title: chapter?.title,
          description: chapter?.description,
          submit: null,
        }}
        validationSchema={Yup.object({
          email: Yup.string().email(),
          // .required(t("Email is required")),
        })}
        onSubmit={(values, helpers) => {
          try {
            dispatchWithToast(
              createOrUpdateDispatch(values),
              (data) => onRefresh(data),
              onClose
            );
          } catch (err) {
            toast.error("Something went wrong...");
            helpers.setStatus({ success: false });
            helpers.setErrors({ submit: err.message });
            // helpers.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <Form>
            <div className="grid grid-cols-6 gap-6 mt-10">
              <div className="col-span-6 sm:col-span-6">
                <PMField
                  description="Hold tom for å generere automatisk"
                  name="chapter_number"
                  type="text"
                  label={t("Kapittel nummer")}
                  {...formik}
                />
              </div>

              <div className="col-span-6 sm:col-span-6">
                <PMField
                  name="title"
                  type="text"
                  label={t("Tittel")}
                  {...formik}
                />
              </div>

              <div className="col-span-6 sm:col-span-6">
                <PMField
                  multiline
                  name="description"
                  type="text"
                  label={t("Beskrivelse")}
                  {...formik}
                />
              </div>
            </div>
            <div className="border-top border-2 border-blue-400 mt-10" />

            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                type="button"
                className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                onClick={onClose}
              >
                {t("Close")}{" "}
              </button>

              {readOnly ? null : (
                <LoadingButton
                  loading={isLoading}
                  disabled={isLoading}
                  loadingPosition="start"
                  startIcon={<CheckBadgeIcon />}
                  type="button"
                  variant="contained"
                  color={"success"}
                  onClick={formik.submitForm}
                >
                  {chapter.id ? t("Update") : t("Create")}
                </LoadingButton>
              )}
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  const [selectedTab, setSelectedTab] = useState(0);

  const handleRefresh = (data) => {
    setItemState((prevState) => ({
      ...prevState,
      chapter: data,
      isLoading: false,
    }));
  };

  const handleLoading = () => {
    setItemState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
  };

  const modalHeaderActions = [
    {
      label: t("Steng"),
      icon: XMarkIcon,
      action: () => onClose(),
    },
  ];

  return (
    <PMDialog
      open={open}
      onClose={onClose}
      headerActions={modalHeaderActions}
      isLoading={isLoading}
      title={
        readOnly
          ? chapter?.id
            ? chapter?.title
            : t("Kapittel")
          : chapter?.id
          ? chapter?.displayTitle
          : t("Opprett kapittel")
      }
    >
      <>
        <ChapterForm />
      </>
    </PMDialog>
  );
}

QualityChapterDialog.defaultProps = {
  selected: null,
  // Read only is used when only wanting to display info. Update or creating will not work.
  readOnly: false,
  open: false,
  selection: false,
  onClose: () => toast.error("Missing onClose function. Cannot close dialog"),
  redirect: false,
};
