export const fieldTypeOptions = [
  {
    id: "1",
    value: "1",
    label: "Low",
    title: "Low",
    class: "bg-green-100 text-green-800",
  },
  {
    id: "2",
    value: "2",
    label: "Medium",
    title: "Medium",
    class: "bg-yellow-100 text-yellow-800",
  },
  {
    id: "3",
    value: "3",
    label: "High",
    title: "High",
    class: "bg-orange-100 text-orange-800",
  },
  {
    id: "4",
    value: "4",
    label: "Critical",
    title: "Critical",
    class: "bg-red-100 text-red-800",
  },
];
