import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ProductsFilter } from "../../../../components/dashboard/products/products-filter";

import { useSearchParams } from "react-router-dom";
import DashboardHeader from "../../../../components/dashboard/dashboardHeader";
import {
  ArrowUpCircleIcon,
  CheckBadgeIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { updateUrlWithException } from "../../../../components/Utils";
import { debounce } from "lodash";
import { getQualityChaptersAsync } from "../../../../slices/quality/quality_chapter";
import { QualityChaptersTable } from "../../../../components/dashboard/quality/qualities/chapters/table";
import QualityChapterDialog from "../../../../components/dashboard/quality/qualities/chapters/dialog";

export const QualityChapters = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const [controller, setController] = useState({
    filters: [],
    page: searchParams.get("page") ? searchParams.get("page") : 1,
    per_page: searchParams.get("per_page") ? searchParams.get("per_page") : 20,
    query: searchParams.get("query") ? searchParams.get("query") : "",
    sort: "asc",
    sortBy: "chapter_number",
    fields: "id,chapter_number, title, description, created_at, updated_at",
  });

  const chapterState = useSelector((store) => {
    return store.quality_chapters;
  });

  // Update all params from controller except filters (dont't touch filters)
  useEffect(() => {
    updateUrlWithException(
      // These will be updated
      {
        page: controller.page,
        per_page: controller.per_page,
        query: controller.query,
        sort: controller.sort,
        sortBy: controller.sortBy,
        view: controller.view,
      },
      // This will be preserved
      "filters"
    );
  }, [controller]);

  const { isLoading, totalCount, newCount } = chapterState;
  const [userOptions, setUserOptions] = useState({
    isLoading: true,
    error: false,
    errors: [],
    data: [],
  });

  const handleQueryChange = (newQuery) => {
    setController({
      ...controller,
      page: 1,
      query: newQuery,
    });
  };

  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const debouncedDispatchAction = debounce((controller) => {
    dispatch(getQualityChaptersAsync(controller));
  }, 250);

  useEffect(() => {
    // Trigger the debounced dispatch action
    debouncedDispatchAction(controller);

    // Clean the debounced function on component unmount.
    return () => {
      debouncedDispatchAction.cancel();
    };
  }, [controller, dispatch]);

  const sortByOptions = [
    // { name: "Seneste (Rapportnr.)", sort: "asc", sortBy: "report_number" },
    // { name: "Eldste (Rapportnr.)", sort: "desc", sortBy: "report_number" },
  ];

  const userController = {
    filters: [{ name: "role", value: "deviation" }],
    page: 1,
    query: "",
    sort: "desc",
    sortBy: "id",
    view: "all",
  };

  const initialFilters = [];

  const [deviationNewDialogOpen, setDeviationNewDialogOpen] = useState(false);

  const headerMeta = [
    {
      label: "Total: " + totalCount,
      description: "",
      path: null,
      action: null,
      icon: CheckBadgeIcon,
      textColor: "text-gray-400",
      iconColor: "text-gray-400",
      hidden: false,
    },

    {
      label: "New: " + newCount,
      description: "",
      path: null,
      action: null,
      icon: ArrowUpCircleIcon,
      textColor: "text-gray-400",
      iconColor: "text-green-500",
      hidden: false,
    },
  ];

  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={[]}
        title={"Kvalitetssikring - Kapitler"}
        meta={headerMeta}
        mainButton={{
          label: "New",
          action: () => setDeviationNewDialogOpen(true),
          path: null,
          color: "",
          icon: PlusIcon,
        }}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-3 lg:col-start-1 sm:col-span-3">
            <ProductsFilter
              className={"mt-10"}
              disabled={isLoading}
              onQueryChange={handleQueryChange}
              query={controller.query}
              initialFilters={initialFilters}
            />

            <QualityChaptersTable
              itemState={chapterState}
              itemsCount={chapterState.totalCount}
              currentCount={chapterState.currentCount}
              page={controller.page}
              onPageChange={handlePageChange}
              perPage={controller.per_page}
            />
          </div>
        </div>
      </DashboardHeader>

      <QualityChapterDialog
        open={deviationNewDialogOpen}
        onClose={() => setDeviationNewDialogOpen(false)}
        selected={null}
        onRefresh={() => {}}
      />
    </>
  );
};
