import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { deleteQuality, fetchQuality } from "../../../../api/quality/quality";
import {
  axiosWithToast,
  HtmlTextFormatter,
  StringLimit,
} from "../../../../components/Utils";
import RoleOnly, { useUserHasAccess } from "../../../../routes/roleOnly";
import { Popup } from "../../../../components/utils/Div";
import {
  ArrowLeftIcon,
  ClipboardDocumentCheckIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { ResourceLoading } from "../../../../components/utils/ResourceLoading";
import { ResourceError } from "../../../../components/utils/ResourceError";
import { ResourceEmpty } from "../../../../components/utils/ResourceEmpty";
import DashboardHeader from "../../../../components/dashboard/dashboardHeader";
import {
  deleteQualityChapter,
  fetchQualityChapter,
} from "../../../../api/quality/quality_chapter";
import { PMField } from "../../../../components/utils/PMField";
import QualityChapterDialog from "../../../../components/dashboard/quality/qualities/chapters/dialog";
import QualityDialog from "../../../../components/dashboard/quality/qualities/quality/dialog";

export const QualityChapterView = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  const canDeleteChapter = useUserHasAccess({
    role: "hse_qualities",
    action: "delete",
  });

  const canCreateQuality = useUserHasAccess({
    role: "hse_qualities",
    action: "create",
  });

  const [itemState, setItemState] = useState({
    isLoading: true,
    error: false,
    errors: [],
    chapter: {},
  });

  const getQuality = async () => {
    setItemState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    axiosWithToast(
      fetchQualityChapter(id),
      () => {},
      () => {},
      (data) => {
        setItemState((prevState) => ({
          ...prevState,
          chapter: data,
          isLoading: false,
        }));
      },
      "Loadng chapter..."
    );
  };

  useEffect(() => {
    getQuality();
  }, [dispatch, id, open]);

  const handleOnRefresh = (data) => {
    if (data?.id) {
      setItemState((prevState) => ({
        ...prevState,
        chapter: data,
      }));
    } else {
      getQuality();
    }
  };

  const handleQualityOnRefresh = (data) => {
    if (data?.id) {
      // change only the current item (quality) inside the chapter -> qualities
      const newChapter = {
        ...chapter,
        qualities: [...chapter.qualities, data],
      };

      // replace if existing
      const index = chapter.qualities.findIndex((item) => item.id === data.id);

      if (index > -1) {
        newChapter.qualities[index] = data;
      }

      // update the state

      setItemState((prevState) => ({
        ...prevState,
        chapter: newChapter,
      }));
    } else {
      getQuality();
    }
  };

  const [qualityItemDeletePromptOpen, setDeviationItemDeletePromptOpen] =
    useState(false);
  const [selectedQuality, setSelectedQuality] = useState(null);

  const promptDeleteQuality = (item) => {
    setSelectedQuality(item);
    setDeviationItemDeletePromptOpen(true);
  };

  const promptEditQuality = (item) => {
    setSelectedQuality(item);
    setQualityDialogOpen(true);
  };

  const handleDeleteChapter = async () => {
    const payload = {
      chapter_id: chapter?.id,
      id: chapter?.id,
    };
    await axiosWithToast(deleteQualityChapter(payload));
  };

  const { chapter, isLoading, error, errors } = itemState;

  const [chapterDialogOpen, setChapterDialogOpen] = useState(false);

  const [qualityDialogOpen, setQualityDialogOpen] = useState(false);

  const headerMeta = [];

  const headerActions = [
    {
      component: null,
      label: "Slett",
      description: "",
      path: null,
      action: () => handleDeleteChapter(),
      icon: TrashIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: canDeleteChapter
        ? chapter?.qualities?.length > 0
          ? true
          : false
        : false,
      menu: true,
    },

    {
      component: null,
      label: "Ny retningslinje",
      description: "",
      path: null,
      action: () => setQualityDialogOpen(true),
      icon: PencilIcon,
      textColor: "text-white",
      bgColor: "bg-green-400",
      hidden: canCreateQuality ? false : true,
      menu: false,
    },
  ];

  const QualityItem = ({ item }) => {
    return (
      <>
        <section
          className="lg:max-w[7xl]"
          aria-labelledby="applicant-information-title"
        >
          <div className="bg-white shadow sm:rounded-lg">
            <div className="flex items-center px-4 py-5 sm:px-6 w-full">
              <div className="flex justify-between w-full">
                <Link to={"/dashboard/quality/qualities/" + item?.id}>
                  <h2
                    id="applicant-information-title"
                    className="text-md font-medium leading-6 text-gray-900 "
                  >
                    {item?.title}
                  </h2>
                </Link>

                <div className="flex justify-between">
                  <RoleOnly role="hse_qualities" action="destroy">
                    <div className="px-4 cursor-pointer">
                      <Popup content="Rediger retningslinje">
                        <button
                          type="button"
                          onClick={() => promptEditQuality(item)}
                        >
                          <PencilIcon className="h-5 w-5 text-blue-400" />
                        </button>
                      </Popup>
                    </div>
                  </RoleOnly>

                  <RoleOnly role="hse_qualities" action="create">
                    <div className="cursor-pointer">
                      <Popup content="Slett linje">
                        <button
                          type="button"
                          onClick={() => promptDeleteQuality(item)}
                        >
                          <TrashIcon className="h-5 w-5 text-red-600" />
                        </button>
                      </Popup>
                    </div>
                  </RoleOnly>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

  const Content = () => {
    if (isLoading) {
      return <ResourceLoading size="base" />;
    }

    if (error) return <ResourceError errors={errors} />;

    if (!chapter?.id) {
      return <ResourceEmpty />;
    }

    return (
      <>
        <div className="space-y-6 lg:col-span-2 lg:col-start-1 sm:col-span-2 max-sm:mt-5 max">
          {chapter?.checklist_item?.checklist?.id && (
            <div>
              <Link
                to={
                  "/dashboard/quality/checklists/" +
                  chapter?.checklist_item?.checklist?.id
                }
                className="bg-blue-400 flex px-5 py-4 rounded mx-auto text-white hover:bg-blue-500"
              >
                <ArrowLeftIcon className="h-5 w-5 mt-0.5" />
                <span className="ml-2">Back to Checklist</span>
              </Link>
            </div>
          )}

          <section
            className="lg:max-w[7xl]"
            aria-labelledby="applicant-information-title"
          >
            <div className="bg-white shadow sm:rounded-lg">
              <div className="flex items-center px-4 py-5 sm:px-6 w-full">
                <div className="block w-[60%]">
                  <h2
                    id="applicant-information-title"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Kapittel Nummber
                  </h2>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    {chapter?.chapter_number}
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section
            className="lg:max-w[7xl]"
            aria-labelledby="applicant-information-title"
          >
            <div className="bg-white shadow sm:rounded-lg">
              <div className="flex items-center px-4 py-5 sm:px-6 w-full">
                <div className="block w-[60%]">
                  <h2
                    id="applicant-information-title"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Beskrivelse
                  </h2>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    {chapter?.description}
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="bold lg:col-span-2 lg:col-start-1 sm:col-span-2 max-sm:mt-5">
          <h2 className="font-bold">Retningslinjer</h2>
        </div>

        {chapter?.qualities?.length > 0 ? (
          chapter?.qualities?.map((item, idx) => (
            <div
              key={idx}
              className="lg:col-span-2 lg:col-start-1 sm:col-span-2"
            >
              <QualityItem item={item} />
            </div>
          ))
        ) : (
          <div className="space-y-6 lg:col-span-2 lg:col-start-1 sm:col-span-2 max-sm:mt-5 max">
            <ResourceEmpty text="I dette kapitlet finnes det ingen retningslinjer akkurat nå." />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <DashboardHeader
        subtitle=""
        actions={headerActions}
        title={chapter?.title || "Mangler title"}
        meta={headerMeta}
        mainButton={{
          label: "Rediger",
          action: () => setChapterDialogOpen(true),
          path: null,
          color: "",
          icon: PencilIcon,
        }}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-1 mb-20">
          <Content />

          <QualityChapterDialog
            open={chapterDialogOpen}
            onClose={() => setChapterDialogOpen(false)}
            selected={chapter}
            onRefresh={(data) => {
              handleOnRefresh(data);
            }}
          />

          <QualityDialog
            open={qualityDialogOpen}
            onClose={() => {
              setQualityDialogOpen(false);
              setSelectedQuality(null);
            }}
            chapter={chapter}
            selected={selectedQuality}
            onRefresh={(data) => {
              handleQualityOnRefresh(data);
            }}
          />
        </div>
      </DashboardHeader>
    </>
  );
};
