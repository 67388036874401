import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { PMField } from "../../../utils/PMField";
import { dispatchWithToast, mapErrors } from "../../../Utils";
import {
  createCustomerAsync,
  updateCustomerAsync,
} from "../../../../slices/customer";
import { ResourceError } from "../../../utils/ResourceError";
import { fetchCustomer } from "../../../../api/customer";
import {
  ArrowUpOnSquareIcon,
  CheckBadgeIcon,
  PencilIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import ItemSelector from "../../../utils/Selector/ItemSelector";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import { PMSimpleSelectorField } from "../../../utils/PMSimpleSelector/PMSimpleSelectorField";
import PMListbox from "../../../utils/PMListbox";
import {
  fetchCustomerSystem,
  fetchCustomerSystems,
  fetchSystemTypes,
} from "../../../../api/customer/system";
import {
  createSystemAsync,
  updateSystemAsync,
} from "../../../../slices/customer/system";
import ProductSlideover from "../../products/product-slideover";
import PMCombobox from "../../../utils/PMCombobox";
import { PMDialog } from "../../../utils/PMDialog";
import { PMNotice } from "../../../utils/notifications";
import { SystemSelector } from "../../../utils/moduleSelectors/systemSelector";
import { use } from "i18next";

export const CustomerSystemForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Deconstructing props
  const { customer, selected, onAttachSystem, onRefresh } = props;

  const [selectedSystem, setSelectedSystem] = useState(null);

  useEffect(() => {
    setSelectedSystem(selected?.id);
  }, [selected]);

  const [itemState, setItemState] = useState({
    isLoading: false,
    error: false,
    errors: [],
    system: {},
    systems: [],
    systemTypes: [],
  });

  const { system, systems, systemTypes, isLoading, error, errors } = itemState;

  const [readOnly, setReadOnly] = useState(true);

  const [productSlideroverOpen, setProductSlideoverOpen] = useState(false);

  const [newSystem, setNewSystem] = useState(false);

  const getCustomerSystems = async (customerId) => {
    return fetchCustomerSystems(customerId)
      .then((res) => {
        if (res?.data) {
          setItemState((prevState) => ({
            ...prevState,
            isLoading: false,
            systems: res?.data?.data,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err?.data?.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          systems: {},
          error: true,
          errors: errors,
        }));
      });
  };

  const getSystemTypes = async () => {
    return fetchSystemTypes()
      .then((res) => {
        if (res.data) {
          setItemState((prevState) => ({
            ...prevState,
            isLoading: false,
            systemTypes: res.data,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err?.data?.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          systems: {},
          error: true,
          errors: errors,
        }));
      });
  };

  const getCustomerSystem = async () => {
    setItemState((prevState) => ({ ...prevState, isLoading: true }));

    return fetchCustomerSystem(customer?.id, selectedSystem)
      .then((res) => {
        if (res?.data?.id) {
          setItemState((prevState) => ({
            ...prevState,
            isLoading: false,
            system: res?.data,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err?.data?.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          system: {},
          error: true,
          errors: errors,
        }));
      });
  };

  useEffect(() => {
    if (open) {
      if (selectedSystem) {
        getCustomerSystem();
      }
      if (customer) {
        getCustomerSystems(customer?.id);
      }
      getSystemTypes();
    }
  }, [open, selectedSystem]);

  const createOrUpdateDispatch = (data) => {
    let res = null;

    if (system?.id) {
      res = dispatch(updateSystemAsync(data));
    } else {
      res = dispatch(createSystemAsync(data));
    }

    return res;
  };

  const systemTypeOptions = () => {
    const types = [];
    systemTypes?.map((type) => {
      types.push({
        name: "system_type_id",
        label: type.title,
        title: type.title,
        value: type.id,
      });
    });
    return types;
  };

  const [productSelectorOpen, setProductSelectorOpen] = useState(false);

  const handleRowProductSelect = () => {
    setProductSelectorOpen(true);
  };

  const handleProductSelect = (data, formik) => {
    const { setFieldValue } = formik;
    const product = data[0];
    setProductSelectorOpen(false);
    if (product) {
      setFieldValue("product_id", product.id);
      setFieldValue("product", product);
    }
  };

  const handleSetNewSystem = () => {
    if (newSystem) {
      setNewSystem(!newSystem);
      setSelectedSystem(null);
      setItemState((prevState) => ({
        ...prevState,
        isLoading: false,
        system: {},
      }));
    } else {
      setSelectedSystem(selected?.id);
      setNewSystem(!newSystem);
    }
  };

  const systemForm = (formik) => (
    <div className="grid grid-cols-6 gap-6 mt-10">
      {system?.product ? (
        <>
          <div className="col-span-6 sm:col-span-6">
            <ul
              role="list"
              className="mt-6 divide-y divide-gray-200 border-t border-gray-200 text-sm font-medium text-gray-500"
            >
              <li key={0} className="flex space-x-6 py-6">
                <img
                  // src={product.imageSrc}
                  // alt={product.imageAlt}
                  className="h-24 w-24 flex-none rounded-md bg-gray-100 object-cover object-center"
                />
                <div className="flex-auto space-y-1">
                  <h3 className="text-gray-900">{system?.product?.title}</h3>
                  {system?.product?.item_number}

                  <p>{system?.product?.short_description}</p>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    navigate("/dashboard/products/" + system?.product?.id)
                  }
                >
                  <p className="flex-none font-medium text-gray-900">
                    <ArrowUpOnSquareIcon className="w-5 h-5 m-2" />
                  </p>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => setProductSlideoverOpen(true)}
                >
                  <div className="h-15 inline-flex items-center rounded-md border border-transparent bg-yellow-500 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    {t("Spareparts")}
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <ProductSlideover
            open={productSlideroverOpen}
            onClose={() => setProductSlideoverOpen(false)}
            view="spareparts"
            productId={system?.product?.id}
          />
        </>
      ) : null}

      <div className="flex justify-between col-span-6 sm:col-span-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {t("Rediger System")}
        </h3>
        <div className="text-right right-0">
          {readOnly && (
            <button
              className="text-right bg-blue-400 px-5 py-2 mr-2"
              type="button"
              onClick={() => setReadOnly(!readOnly)}
            >
              Rediger system
            </button>
          )}
          <button
            className="text-right bg-green-400 px-5 py-2"
            type="button"
            onClick={() => handleSetNewSystem()}
          >
            Velg eksisterende system
          </button>
        </div>
      </div>

      <div className="col-span-6 sm:col-span-3">
        <PMCombobox
          name="system_type_id"
          label={t("System Type")}
          options={systemTypeOptions()}
          {...formik}
        />
      </div>

      <div className="col-span-6 sm:col-span-3">
        <label
          htmlFor="name"
          className="block text-sm font-medium text-gray-700"
        >
          <label> {t("Product")} </label>
        </label>
        <div
          className="cursor-pointer mt-1 block w-full p-2 border-0 border-b border-transparent bg-blue-50 focus:border-blue-500 focus:ring-0 sm:text-sm"
          onClick={!readOnly ? () => handleRowProductSelect() : null}
        >
          {formik.values.product?.title ? (
            <div className="font-bold p-2 border-b-4 border-indigo-300">
              {formik.values?.product?.displayTitle}
            </div>
          ) : (
            <div className="text-gray-400">Velg produkt</div>
          )}
        </div>

        <ItemSelector
          {...props}
          multiple={false}
          open={productSelectorOpen}
          selectorCloseCallback={() => setProductSelectorOpen(false)}
          selectorType="products"
          module={"product"}
          // module_param={product.id}
          // selectedItems={[]}
          onSelect={(data) => handleProductSelect(data, formik)}
          // selectedItemsCallback={handleSelectedItemsCallback}
          filters={[
            { name: "status", value: "active" },
            { name: "hasSpareParts", value: true },
            // { name: 'categories', value: '44' }
          ]}
        />
      </div>
      <div className="col-span-6 sm:col-span-3">
        <PMField
          name="system_m_name"
          type="text"
          label={t("Model name")}
          readOnly={readOnly}
          {...formik}
        />
      </div>
      <div className="col-span-6 sm:col-span-3">
        <PMField
          name="serial_number"
          type="text"
          label={t("Serial Number")}
          readOnly={readOnly}
          {...formik}
        />
      </div>
      <div className="col-span-6 sm:col-span-3">
        <PMField
          name="placement"
          type="text"
          label={t("Placement")}
          readOnly={readOnly}
          {...formik}
        />
      </div>

      <div className="col-span-6 sm:col-span-3">
        <PMField
          name="installation_year"
          type="number"
          label={t("Installation Year")}
          readOnly={readOnly}
          {...formik}
        />
      </div>

      <div className="col-span-6 sm:col-span-3">
        <PMField
          name="production_year"
          type="number"
          label={t("Production Year")}
          readOnly={readOnly}
          {...formik}
        />
      </div>
    </div>
  );

  const attachSystem = (formik) => (
    <div className="grid grid-cols-6 gap-6 mt-10">
      <div className="flex justify-between col-span-12 sm:col-span-12">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {t("Velg System")}
        </h3>
        <div className="text-right right-0">
          <button
            className="text-right bg-green-400 px-5 py-2"
            type="button"
            onClick={() => setNewSystem(true)}
          >
            Nytt System
          </button>
        </div>
      </div>
      <div className="col-span-12 sm:col-span-12">
        <div className="flex items-center">
          <div className="w-full p-2">
            <SystemSelector
              module="customer"
              module_param={customer?.id}
              onChange={onAttachSystem}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const displayForm = (formik) => {
    if (newSystem && !selectedSystem) {
      return systemForm(formik);
    } else {
      if (!selectedSystem) {
        if (systems?.length > 0) {
          if (newSystem) {
            return systemForm(formik);
          } else {
            return attachSystem();
          }
        } else {
          // setReadOnly(false);
          return systemForm(formik);
        }
      } else {
        return systemForm(formik);
      }
    }
  };

  const systemOptions = () => {
    const label = (system) => {
      if (system?.product) {
        return system?.product?.title + " " + system.serial_number;
      } else {
        return system.serial_number;
      }
    };

    const array = [{ name: "system_id", label: "Ikke valgt", value: 0 }];
    systems?.map((system) => {
      array.push({
        name: "system_id",
        label: label(system),
        value: system.id,
      });
    });
    return array;
  };

  if (!customer?.id) {
    return (
      <PMNotice
        title="No customer selected"
        description="Kan ikke opprette/ knytte system til rapporten"
      />
    );
  }

  const handleOnRefresh = (data) => {
    console.log(data);

    if (onRefresh) {
      onRefresh(data);
    }

    if (onAttachSystem) {
      onAttachSystem({
        target: {
          value: data?.id,
        },
      });
    }

    setItemState((prevState) => ({
      ...prevState,
      isLoading: false,
      system: data,
    }));
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        id: system?.id,
        customer_id: customer?.id,
        product: system?.product,
        product_id: system?.product_id,
        system_m_name: system?.system_m_name,
        serial_number: system?.serial_number,
        installation_year: system?.installation_year,
        production_year: system?.production_year,
        system_type: system?.system_type,
        system_type_id: system?.type?.id,
        submit: null,
      }}
      validationSchema={Yup.object({
        serial_number: Yup.string().required(t("Serial Number is required")),
      })}
      onSubmit={(values, helpers) => {
        try {
          dispatchWithToast(createOrUpdateDispatch(values), (data) =>
            handleOnRefresh(data)
          );
          setReadOnly(true);
          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
        } catch (err) {
          toast.error("Something went wrong...");
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
          // helpers.setSubmitting(false);
        }
      }}
    >
      {(formik) => (
        <Form>
          {displayForm(formik)}

          <div className="mt-10 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-3 sm:gap-3">
            {readOnly && !newSystem ? null : (
              <LoadingButton
                loading={formik.isSubmitting}
                disabled={isLoading}
                loadingPosition="start"
                startIcon={<CheckBadgeIcon />}
                type="button"
                variant="contained"
                color={"success"}
                onClick={formik.submitForm}
              >
                {t("Lagre og knytt system")}
              </LoadingButton>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export const CustomerSystemDialog = (props) => {
  const { open, onClose } = props;

  return <PMDialog open={open} onClose={onClose} title="System"></PMDialog>;
};

CustomerSystemDialog.defaultProps = {
  selected: null,
  // Read only is used when only wanting to display info. Update or creating will not work.
  readOnly: false,
  open: false,
  onClose: () => toast.error("Missing onClose function. Cannot close dialog"),
};
