import axios from "../axios";

export async function getQualityPdf(payload) {
  return axios.get(`/quality/${payload.id}/pdf`, {
    params: payload.params,
    type: "application/pdf",
  });
}

export async function fetchQualitys(controller) {
  return axios({
    method: "get",
    url: "quality/qualities",
    params: controller,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchQuality(id) {
  return axios.get(`/quality/qualities/` + id);
}

export async function createQuality(payload) {
  return axios
    .post(`/quality/qualities`, { quality: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function updateQuality(payload) {
  return axios
    .put(`/quality/qualities/${payload.id}`, { quality: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function deleteQuality(payload) {
  const quality = payload.quality;
  return axios
    .delete(`/quality/qualities/${quality.quality_id}`, {
      data: JSON.stringify({
        quality,
      }),
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
