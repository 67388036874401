import axios from "../axios";

export async function getQualityChapterPdf(payload) {
  return axios.get(`/quality/chapters/${payload.id}/pdf`, {
    params: payload.params,
    type: "application/pdf",
  });
}

export async function fetchQualityChapters(controller) {
  return axios({
    method: "get",
    url: "quality/qualities/chapters",
    params: controller,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export async function fetchQualityChapter(id) {
  return axios.get(`/quality/qualities/chapters/` + id);
}

export async function createQualityChapter(payload) {
  return axios
    .post(`/quality/qualities/chapters`, { chapter: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function updateQualityChapter(payload) {
  return axios
    .put(`/quality/qualities/chapters/${payload.id}`, { chapter: payload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function deleteQualityChapter(payload) {
  const chapter = payload.chapter;
  return axios
    .delete(`/quality/qualities/chapters/${chapter.chapter_id}`, {
      data: JSON.stringify({
        chapter,
      }),
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
