import { PencilIcon } from "@heroicons/react/24/outline";
import { PMButton } from "../../utils/PMButton";
import { useState } from "react";
import { PMField } from "../../utils/PMField";
import { FormikSaveBar } from "../../utils/FormikSaveBar";
import PMSwitch from "../../utils/PMSwitch";
import PMCombobox from "../../utils/PMCombobox";
import { fieldTypeOptions } from "./utils";

export const FieldsSection = ({ module, moduleParam }) => {
  const initFields = [
    {
      name: "field1",
      value: "value1",
      editable: false,
    },
    {
      name: "field2",
      value: "value2",
      editable: false,
      type: "switch",
    },

    {
      name: "field3",
      value: "value3",
      editable: false,
      type: "combobox",
    },
  ];

  const [fields, setFields] = useState(initFields);

  const onEdit = (field) => {
    // change fields, current row, to etitable: true
    // change fields, other rows, to etitable: false

    const newFields = fields.map((f) => {
      if (f.name === field.name) {
        return { ...f, editable: true };
      } else {
        return { ...f, editable: false };
      }
    });
    setFields(newFields);
  };

  const ValueField = ({ field }) => {
    const type = field.type;

    switch (type) {
      case "textarea":
        return (
          <PMField
            name="value"
            label="Value"
            multiline
            // {...formik}
          />
        );

      case "switch":
        return <PMSwitch name="value" label="Value" selected={field?.value} />;
      default:
        return <PMField name="value" value={field?.value} />;
    }
  };
  const Field = ({ module, moduleParam, field }) => {
    return (
      <div className=" bg-white rounded-md py-2">
        <div className="flex justify-between">
          <div className="py-2 px-2">
            {field?.editable ? (
              <PMField name="name" value={field?.name} />
            ) : (
              field.name
            )}
          </div>

          <div className="py-2 px-2">
            {field?.editable ? <ValueField field={field} /> : field.value}
          </div>

          <div className="py-2 px-2">
            {field?.editable ? (
              <PMSwitch
                name="onDisplay"
                label="Display"
                selected={field?.onDisplay}
              />
            ) : (
              field.onDisplay
            )}
          </div>

          {field?.editable && <div className="py-2 px-2">Type</div>}

          <div className="py-2 px-2">
            <PMButton type="button" onClick={() => onEdit(field)}>
              <PencilIcon className="h-4 w-4" />
            </PMButton>
          </div>
        </div>
        <div>
          {field?.editable && (
            <div className="py-2 px-2">
              <PMCombobox
                name="type"
                label="Type"
                options={fieldTypeOptions}
                // {...formik}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>
        {fields.map((field, index) => (
          <div key={index} className="py-2">
            <Field module={module} moduleParam={moduleParam} field={field} />
          </div>
        ))}
      </div>

      {/* <FormikSaveBar /> */}
    </div>
  );
};
