/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, ClockIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getReportsAsync } from "../../../../slices/service/reports";
import { HtmlTextFormatter } from "../../../Utils";
import { PMButton } from "../../../utils/PMButton";
import ReportFinishDialog from "./report-finish-dialog";
import ReportSummary from "./report-summary";
import { ReportStatus } from "./utils";

export default function ReportHistorySlideover(props) {
  const { open, onClose, systemId, view } = props;
  const dispatch = useDispatch();
  const [controller, setController] = useState({
    filters: [{ name: "system", value: systemId }],
    fields:
      "id, displayTitle, description, report, report_type, finished, status, appointment",
    allReports: true,
    page: 1,
    per_page: 40,
    query: "",
    sort: "asc",
    sortBy: "created_at",
  });

  const reportState = useSelector((store) => {
    return store.reports;
  });

  useEffect(() => {
    setController({
      ...controller,
      filters: [{ name: "system", value: systemId }],
    });
  }, [systemId]);

  useEffect(() => {
    if (open) {
      dispatch(getReportsAsync(controller));
    }
  }, [open]);

  const { data, isLoading } = reportState;

  const [finishReportDialogOpen, setFinishReportDialogOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);

  const openReportReport = (report) => {
    setSelectedReport(report);
    setFinishReportDialogOpen(true);
  };

  const reports = () => (
    <ul
      role="list"
      className="divide-y divide-gray-200 border-t border-b border-gray-200"
    >
      {data?.map((report) => (
        <li
          key={report.id}
          className="flex py-6 cursor-pointer"
          onClick={() => openReportReport(report)}
        >
          <div className="flex-shrink-0"></div>

          <div className="ml-4 flex flex-1 flex-col sm:ml-6">
            <div>
              <div className="flex justify-between">
                <h4 className="text-sm">
                  <Link
                    to={"/dashboard/service/reports/" + report.id}
                    className="font-medium text-gray-700 hover:text-gray-800"
                  >
                    <span className="text-lg font-bold">
                      {report?.displayTitle}
                    </span>
                    <span className="italic text-gray-500">
                      {report?.appointment && report?.appointment?.date
                        ? " / " + report?.appointment?.date
                        : null}
                    </span>
                  </Link>
                </h4>
                <div>
                  <ReportStatus report={report} />
                </div>
              </div>
              <p className="mt-1 text-sm text-gray-500 italic">
                {report?.report_type?.displayTitle}
              </p>
              <p className="mt-1 py-2 text-sm text-gray-500">
                <HtmlTextFormatter string={report?.description} />
              </p>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-lg">
                    <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">
                              Historikk
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={onClose}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-indigo-300">
                              For valgt system
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-200 px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              {reports()}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-end px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={onClose}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <ReportSummary
        open={finishReportDialogOpen}
        onClose={() => setFinishReportDialogOpen(false)}
        selected={selectedReport}
        onRefresh={props.onRefresh}
        isLoading={isLoading}
      />
    </>
  );
}

ReportHistorySlideover.defaultProps = {
  open: false,
  onClose: () => {},
};
