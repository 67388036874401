import React, { useState, useEffect } from "react";
import axios from "../../../api/axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Grid,
  Box,
} from "@mui/material";
import { TreeView, TreeItem } from "@mui/lab";
import {
  ChevronRightIcon,
  ArrowsPointingOutIcon,
  ArrowUpOnSquareIcon,
} from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { BuildingOfficeIcon, UserIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { PMPagination } from "../PMPagination";
import { TWclassNames } from "../Div";
import { InfoListPopup } from "../infoListPopup";
import {
  findProductStatusByValue,
  findProductTypeById,
  findUnitById,
  findWarehouseStatusById,
} from "../../dashboard/products/product-data";
import { ProductLastUpdatedStatus } from "../../dashboard/products/utils";
import { PMDialog } from "../PMDialog";
import { PMField } from "../PMField";
import { debounce } from "lodash";
import { DocumentImage, documentSrc } from "../../dashboard/documents/utils";

export default function ItemSelector(props) {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: false,
    error: false,
  });
  const [categories, setCategories] = useState([]);
  const [items, setItems] = useState([]);
  const [currentCount, setCurrentCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchString, setSearchString] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectorAttributes, setSelectorAttributes] = useState({
    title: "",
    categorySelector: false,
    updateLink: null,
    getLink: null,
    getParam: null,
    getItemsLink: null,
    updateParams: [],
  });
  const { selectorType, open, module, onSelect, multiple } = props;
  const [currentNavSelect, setCurrentNavSelect] = useState(1);
  const [currentView, setCurrentView] = useState("all");
  const [params, setParams] = useState({
    filters: props.filters || [],
    fields:
      "id,displayTitle,title,item_number,brand,status,warehouse_status_type_id,unit_id,product_type,short_description,updated_at,purchase_price,sales_price,main_picture",
    page: 1,
    query: searchString,
    sort: "desc",
    sortBy: "updated_at",
    view: "all",
    ids: null,
    per_page: 10,
    show_inactive: false,
    [selectorType]: true,
  });
  // Close modal
  const handleClose = () => {
    props.selectorCloseCallback();
  };

  // Get the items (example: products) to fill the selector with.

  const getItems = () => {
    const { getItemsLink } = selectorAttributes;

    if (getItemsLink) {
      axios
        .get(`${getItemsLink}`, { params })
        .then((response) => {
          setState({ loading: false, error: false });
          setItems(response.data.data);
          setCurrentCount(response.data.currentCount);
          setTotalCount(response.data.count);
        })
        .catch((e) => {
          setState({ loading: false, error: true });
          toast.error("Could not fetch items for this selector. Error: " + e);
        });
    }
  };

  // const getCategories = () => {
  //     const params = {
  //         parents: true
  //     }
  //     const fetchItems = async () => {
  //         axios.get(`/api/v1/products/categories/list?parents=true`, params)
  //         .then(response => {
  //             setState({loading: false, error: false});
  //             setCategories(response.data)
  //         }).catch(e => {
  //             setState({loading: false, error: true});
  //         });
  //     }
  //     toast.promise(
  //         fetchItems(),
  //         {
  //             loading: 'Laster inn kategorier',
  //             success: <b>Kategories ble lastet inn!</b>,
  //             error: <b>Kunne ikke laste inn kategorier</b>,
  //         }
  //     )
  // }

  // When we select a product, we add it to selectedProducts state.
  // If we uncheck a product, it will be removed from current state.
  const handleSelectProduct = (checked, id) => {
    const idToString = id.toString();

    if (multiple) {
      if (checked && !selectedProducts.includes(id)) {
        setSelectedProducts((prev) => [...prev, idToString]);
      } else if (selectedProducts.includes(idToString)) {
        const filteredSelectedProducts = selectedProducts.filter((value) => {
          return value !== idToString;
        });
        setSelectedProducts(filteredSelectedProducts);
      }
    } else {
      setSelectedProducts([idToString]);
    }
  };

  // Checking if a product is selected in the selector.
  const checkIfChecked = (id) => {
    const idToString = id.toString();
    if (selectedProducts.length > 0) {
      if (selectedProducts.includes(idToString)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  // Handle searching for products/ items
  // Fetches products depending on the searchString.
  const handleSearch = (e) => {
    e.preventDefault();
    setSearchString(e.target.value);
  };

  // On mount, we will set the attributes for this selector.
  useEffect(() => {
    // This selector will be used for Product Attributes, Product Spare Parts and Product Categories.
    // Set attributes depending on selector type.

    // A selector for adding accessories to a product
    if (props.selectorType === "accessories") {
      setSelectorAttributes({
        title: "Select accessories for this product",
        categorySelector: true,
        updateLink: "/products/",
        showLink: "/dashboard/products/",
        getLink: "/products/",
        getItemsLink: "/products/list",
        getParam: "accessories",
      });
    } else if (props.selectorType === "spareParts") {
      // A selector for adding spare parts to a product
      setSelectorAttributes({
        title: "Select spare parts for this product",
        categorySelector: true,
        showLink: "/dashboard/products/",
        updateLink: "/products/",
        getLink: "/products/",
        getItemsLink: "/products/list",
        getParam: "spare_parts",
      });
      // setParams(prevState => ({
      //     ...prevState,
      //     filters: ['spare_parts'],
      // }))
    }

    // A selector for adding categories to a product
    else if (props.selectorType === "categories") {
      setSelectorAttributes({
        title: "Select categories for this product",
        categorySelector: false,
        showLink: "/dashboard/products/categories/",
        updateLink: "/products/",
        getLink: "/products/",
        getItemsLink: "/products/categories/list",
        getParam: "category_ids",
      });
    }

    // A selector for products that will be added to a order, report or other module..
    else if (props.selectorType === "products") {
      setSelectorAttributes({
        title: "Select products",
        categorySelector: true,
        updateLink: "/products/",
        showLink: "/dashboard/products/",
        getLink: "/products/",
        getItemsLink: "/products/list",
        getParam: "products",
      });
    } else {
      toast.error("No selector type was set for this selector");
    }

    return () => {
      // Cleanup
    };
  }, [open]);

  useEffect(() => {
    setSelectedProducts(props.selectedItems);
  }, [props.selectedItems]);

  const debouncedDispatchAction = debounce((controller) => {
    getItems(controller);
  }, 500);

  useEffect(() => {
    // Trigger the debounced dispatch action
    if (props.selectorType && open === true) {
      debouncedDispatchAction(params);
    }

    // Clean the debounced function on component unmount.
    return () => {
      debouncedDispatchAction.cancel();
    };
  }, [open, searchString, params]);

  // useEffect(() => {
  //     // Fetch selected items from module if attributes is set and if
  //     // selectedItems/ selectedProducts is not allready set.
  //     getModule();

  //     return () => {

  //     }

  // }, [selectorAttributes]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const tabs = [
    { id: 1, name: "All", icon: UserIcon, action: "all" },
    { id: 2, name: "Selected", icon: BuildingOfficeIcon, action: "selected" },
  ];

  const handleNavSelect = (id) => {
    const tab = tabs.find((tab) => tab.id === id);
    setCurrentNavSelect(id);
    setCurrentView(tab.action);
  };

  // Update params as currentView changes.
  useEffect(() => {
    switch (currentView) {
      case "all":
        setParams((prevState) => ({
          ...prevState,
          ids: null,
        }));
        return;
      case "selected":
        setParams((prevState) => ({
          ...prevState,
          ids: selectedProducts.join(","),
        }));
        return;
    }
  }, [currentView]);

  const handleQueryChange = (query) => {
    setParams((prevState) => ({
      ...prevState,
      query: query,
    }));
  };

  const handlePageChange = (page) => {
    setParams((prevState) => ({
      ...prevState,
      page: page,
    }));
  };

  const handleInActiveChange = (boolean) => {
    setParams((prevState) => ({
      ...prevState,
      show_inactive: boolean,
    }));
  };

  const handleSave = () => {
    if (onSelect) {
      // Get the data from selectedProducts
      const matchingIds = selectedProducts.map((id) => parseInt(id));
      const matchingObjects = items.filter((object) =>
        matchingIds.includes(object.id)
      );
      onSelect(matchingObjects);
      return;
    }

    if (!props.module_param) {
      toast.error("ID is not set? Cannot save");
      return;
    }

    if (!selectorAttributes.updateLink) {
      toast.error("updateLink is not set? Cannot save");
      return;
    }

    if (!selectorAttributes.getParam) {
      toast.error("getParam is not set? Cannot save");
      return;
    }

    const request = {
      product: {
        [selectorAttributes.getParam]: selectedProducts,
      },
    };

    const res = axios.put(
      `${selectorAttributes.updateLink}${props.module_param}`,
      request
    );

    toast.promise(res, {
      loading: "Lagrer....",
      success: (data) => {
        if (data.status === 500) throw new Error("Server error");
        return "Data was saved to " + module;
      },
      error: "Something went wrong...could not save!",
    });
  };

  const itemTree = (items) => {
    if (items && items.length > 0) {
      return items?.map((item) => {
        const productInfo = [
          { title: "Artikkelnummer", value: item.item_number },
          { title: "Tittel", value: item.item_number },
          { title: "Beskrivning", value: item.short_description },
          { title: "Märke", value: item?.brand?.title },
          {
            title: "Status",
            value: findProductStatusByValue(item?.status).label,
          },
          {
            title: "Produkt typ",
            value: findProductTypeById(item?.product_type).label,
          },
          {
            title: "Enhet",
            value: item?.unit_id
              ? findUnitById(item?.unit_id).label
              : "Ikke valgt",
          },
          {
            title: "Lagerstatus Type",
            value: item?.warehouse_status_type_id
              ? findWarehouseStatusById(item?.warehouse_status_type_id).label
              : "Ikke valgt",
          },
        ];

        // used for counting descendants of a category.
        // The counting logic with then be used to order or add styling depending on descendant level.
        if (item.ancestry) {
          var ancestryCount = item.ancestry
            .split("/")
            .map((item) => item.trim()).length;
        } else {
          ancestryCount = 0;
        }
        // Return children of main category / descendants
        return (
          <>
            <TableRow
              hover={true}
              selected={checkIfChecked(item.id)}
              key={item.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell padding="checkbox">
                <div className={TWclassNames("flex", `ml-[10px]`)}>
                  <div>
                    <Checkbox
                      checked={checkIfChecked(item.id)}
                      color="primary"
                      onChange={(e) =>
                        handleSelectProduct(e.target.checked, item.id)
                      }
                    />
                  </div>
                </div>
              </TableCell>

              <TableCell>
                {/* <img
                  className="w-20 h-full object-contain rounded-xl"
                  src={documentSrc(item?.main_picture, "thumb")}
                  alt={
                    item?.main_picture?.title
                      ? item?.main_picture?.title
                      : "Image placeholder"
                  }
                /> */}

                <div className="max-w-[100px]">
                  <DocumentImage
                    // pathType={pathType}
                    document={item?.main_picture}
                    // onChange={(data) => {
                    //   handleDocumentChange(data);
                    // }}
                  />
                </div>
              </TableCell>

              <TableCell>
                {item.item_number ? item.item_number : item.id}
              </TableCell>

              <TableCell component="th" scope="row">
                <InfoListPopup info={productInfo}>
                  <span>{item.title}</span>
                </InfoListPopup>
              </TableCell>

              <TableCell component="th" scope="row">
                <ProductLastUpdatedStatus product={item} />
              </TableCell>
              <TableCell>
                <Link
                  to={selectorAttributes.showLink + item.id}
                  target="_blank"
                >
                  <ArrowUpOnSquareIcon className="w-5 h-5" />
                </Link>
              </TableCell>
            </TableRow>

            {/* Recursion  */}
            {/* Returning children's children of main category / descendants and so on...*/}

            {itemTree(item.children)}
          </>
        );
      });
    }
  };

  const navigation = () => {
    return (
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            defaultValue={tabs.find((tab) => tab.id === currentNavSelect).name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  onClick={() => handleNavSelect(tab.id)}
                  className={classNames(
                    currentNavSelect === tab.id
                      ? "border-blue-400 text-blue-400"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-xs"
                  )}
                  aria-current={
                    currentNavSelect === tab.id ? "page" : undefined
                  }
                >
                  <tab.icon
                    className={classNames(
                      currentNavSelect === tab.id
                        ? "text-blue-400"
                        : "text-gray-400 group-hover:text-gray-500",
                      "-ml-0.5 mr-2 h-5 w-5"
                    )}
                    aria-hidden="true"
                  />
                  <span>{tab.name}</span>
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
    );
  };

  const categoryTree = (categories) => {
    if (categories && categories.length > 0) {
      return categories?.map((category) => {
        // Return children of main category / descendants
        return (
          <TreeItem
            key={category.id}
            nodeId={category.id}
            label={category.title}
          >
            {/* Recursion  */}
            {/* Returning children's children of main category / descendants and so on...*/}
            {categoryTree(category.children)}
          </TreeItem>
        );
      });
    }
  };

  const renderProductCategories = () => {
    return (
      <Grid item xs={3}>
        <Grid item>
          <Box
            sx={{ height: 270, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
          >
            <TreeView
              aria-label="disabled items"
              defaultCollapseIcon={<ArrowsPointingOutIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              // disabledItemsFocusable={}
            >
              {categoryTree(categories)}
            </TreeView>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const tableBody = () => {
    if (state.error) {
      <TableRow style={{ padding: 10 }}>
        <TableCell>Something went wrong...</TableCell>
      </TableRow>;
    } else if (items && items.length > 0) {
      return itemTree(items);
    } else {
      return (
        <TableRow style={{ padding: 10 }}>
          <TableCell>No items...</TableCell>
        </TableRow>
      );
    }
  };

  // return (
  //   <div>
  //     <Dialog
  //       fullWidth={true}
  //       maxWidth="lg"
  //       sx={{ overflow: { xs: "hidden", sm: "visible" } }}
  //       open={props.open}
  //       // TransitionComponent={Transition}
  //       keepMounted
  //       onClose={handleClose}
  //       aria-describedby="alert-dialog-slide-description"
  //     >
  //       <DialogTitle>{selectorAttributes.title}</DialogTitle>
  //       <DialogContent>
  //         <Grid container spacing={2}>
  //           {/* {selectorAttributes.categorySelector === true ?
  //                   renderProductCategories()
  //               :
  //                   ''
  //               } */}
  //           <Grid item xs={9}>
  //             <form onSubmit={handleSearch} className="mt-10">
  //               <div className="relative rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
  //                 <label
  //                   htmlFor="name"
  //                   className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
  //                 >
  //                   Search
  //                 </label>
  //                 <div className="mt-1">
  //                   <input
  //                     type="text"
  //                     name="product-search"
  //                     id="product-search"
  //                     onChange={(e) => handleQueryChange(e.target.value)}
  //                     className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
  //                     placeholder="Search...."
  //                   />
  //                 </div>
  //               </div>
  //             </form>
  //             {navigation()}

  //             <Grid item>
  //               <TableContainer component={Paper}>
  //                 <Table sx={{ minWidth: 650 }} aria-label="simple table">
  //                   <TableHead sx={{ fontSize: "10px" }}>
  //                     <TableRow>
  //                       <TableCell padding="checkbox">
  //                         <Checkbox
  //                           color="primary"
  //                           // indeterminate={numSelected > 0 && numSelected < rowCount}
  //                           // checked={rowCount > 0 && numSelected === rowCount}
  //                           // onChange={onSelectAllClick}
  //                           inputProps={{
  //                             "aria-label": "select all desserts",
  //                           }}
  //                         />
  //                       </TableCell>
  //                       <TableCell>Item number</TableCell>
  //                       <TableCell>Title</TableCell>
  //                       <TableCell></TableCell>
  //                       <TableCell></TableCell>
  //                     </TableRow>
  //                   </TableHead>
  //                   <TableBody>{tableBody()}</TableBody>
  //                 </Table>
  //               </TableContainer>
  //             </Grid>

  //             <PMPagination
  //               // disabled={isLoading}
  //               onPageChange={handlePageChange}
  //               page={params.page}
  //               perPage={params.per_page}
  //               rowsCount={currentCount}
  //               totalCount={totalCount}
  //               currentCount={currentCount}
  //               showCount={false}
  //             />
  //           </Grid>
  //         </Grid>
  //       </DialogContent>
  //       <DialogActions>
  //         <Button onClick={handleClose}>Lukk</Button>
  //         <Button onClick={handleSave}>Lagre</Button>
  //       </DialogActions>
  //     </Dialog>
  //   </div>
  // );

  return (
    <div>
      <PMDialog
        size="7xl"
        open={props.open}
        onClose={handleClose}
        title={selectorAttributes.title}
      >
        <div className="w-full">
          <div>
            <div>
              <form onSubmit={handleSearch} className="mt-10">
                <div className="relative rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-400 focus-within:ring-1 focus-within:ring-blue-400">
                  <label
                    htmlFor="name"
                    className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
                  >
                    Search
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="product-search"
                      id="product-search"
                      onChange={(e) => handleQueryChange(e.target.value)}
                      className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                      placeholder="Search...."
                    />
                  </div>
                </div>
              </form>

              <div className="py-5">
                <PMField
                  type="checkbox"
                  onChange={(e) => handleInActiveChange(e.target.checked)}
                  value={params.show_inactive}
                  name="show_inactive"
                  label="Show inactive"
                  {...params}
                />
              </div>
              {navigation()}

              <Grid item>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead sx={{ fontSize: "10px" }}>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            // indeterminate={numSelected > 0 && numSelected < rowCount}
                            // checked={rowCount > 0 && numSelected === rowCount}
                            // onChange={onSelectAllClick}
                            inputProps={{
                              "aria-label": "select all desserts",
                            }}
                          />
                        </TableCell>
                        <TableCell>Bild</TableCell>
                        <TableCell>Item number</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{tableBody()}</TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <PMPagination
                // disabled={isLoading}
                onPageChange={handlePageChange}
                page={params.page}
                perPage={params.per_page}
                rowsCount={currentCount}
                totalCount={totalCount}
                currentCount={currentCount}
                showCount={false}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between my-5 mt-10">
          <button
            type="button"
            onClick={handleClose}
            className="px-5 py-2 bg-red-400 rounded-md shadow hover:bg-red-500 text-white"
          >
            Lukk
          </button>
          <button
            type="button"
            onClick={handleSave}
            className="px-5 py-2 bg-blue-400 rounded-md shadow hover:bg-blue-500 text-white"
          >
            Lagre
          </button>
        </div>
      </PMDialog>
    </div>
  );
}

ItemSelector.defaultProps = {
  multiple: true,
  selectedItems: [],
};
