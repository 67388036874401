import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { dispatchWithToast } from "../../../Utils";
import { updateReportAsync } from "../../../../slices/service/reports";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import { DocumentViewer } from "../../Media/documentViewer";
import { FullReportAddress, ServiceReportOperationStatus } from "./utils";
import { PMDialog } from "../../../utils/PMDialog";
import { PencilIcon } from "@heroicons/react/24/outline";
import { PMNotice } from "../../../utils/notifications";

export default function ReportSummary(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    open,
    onClose,
    selected,
    fields,

    isLoading,
  } = props;

  const report = selected;

  function ItemConsumptions() {
    return (
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
            >
              Artikkelnr.
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Tittel
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Antall
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {report?.items?.map((item) => (
            <tr key={item.id}>
              <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                {item?.product?.item_number}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                {item?.product?.title
                  ? item?.product?.title
                  : item?.description}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                {item?.quantity}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  function Variables() {
    return (
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
            >
              Variable
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Reference
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Field 1
            </th>

            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Field 2
            </th>

            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Unit
            </th>

            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Note
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {report?.variables?.map((variable) => (
            <tr key={variable.id}>
              <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                {variable?.variable_type?.title}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                {variable?.reference}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                {variable?.field1}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                {variable?.field2}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                {variable?.unit?.title}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                {variable?.note}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  function Container({ title, path, children }) {
    return (
      <div>
        <div className="flex justify-between bg-blue-400 rounded-xl px-2 py-2">
          <h3 className="font-semibold border-b-2 border-b-blue-400 py-1">
            {t(title)}
          </h3>
          {path && (
            <Link to={path} className="flex py-1 px-2 hover:text-white">
              <PencilIcon className="h-4 w-4" />
              <span className="ml-2 text-sm">{t("Edit")}</span>
            </Link>
          )}
        </div>
        <div className="px-2 min-h-[150px]">{children}</div>
      </div>
    );
  }

  function Details() {
    return (
      <div className="flex justify-self-auto mt-2 gap-4">
        <div className="flex-auto">
          <h3 className="font-semibold">Customer</h3>
          <p>{report?.customer?.displayTitle}</p>
        </div>

        <div className="flex-auto">
          <h3 className="font-semibold">Project</h3>
          <p>{report?.customer?.name}</p>
        </div>

        <div className="flex-auto">
          <h3 className="font-semibold">Address</h3>
          <p>{FullReportAddress(report)}</p>
        </div>
      </div>
    );
  }

  function Checklist() {
    return (
      <div className="flex-col gap-4">
        {report?.checklist?.items?.map((item) => (
          <div key={item.id} className="mt-2 px-4 py-4 bg-gray-200 rounded-md ">
            <div className="flex justify-between">
              <span>{item.title}</span>
              <span>{item.isApproved ? "✅" : "❌"}</span>
            </div>
            <div>
              {item.description && (
                <div className="mt-2 text-sm text-gray-500">
                  {item.description}
                </div>
              )}
            </div>
            <div>
              {item?.documents && item?.documents.length > 0 && (
                <div className="mt-4">
                  <DocumentViewer
                    pathType="quickview"
                    documents={item.documents}
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <PMDialog
      open={open}
      onClose={onClose}
      title="Rapport summering"
      size={"5xl"}
    >
      <div>
        {!report?.finished && (
          <div className="py-5">
            <>
              {!report?.finished && (
                <PMNotice
                  severity="error"
                  title="Rapporten er ikke ferdigstilt"
                  description="Gå til rapporten for å ferdigstille den."
                />
              )}
            </>
          </div>
        )}
        {isLoading ? (
          <ResourceLoading />
        ) : (
          <>
            <div className="grid gap-5 mt-2">
              <Container title="Details">
                <Details />
              </Container>

              <Container
                title={"Beskrivelse"}
                path={`/dashboard/service/reports/${report?.id}/finish?stage=0`}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: report?.description }}
                />
              </Container>

              <Container
                title={"Report"}
                path={`/dashboard/service/reports/${report?.id}/finish?stage=0`}
              >
                <div dangerouslySetInnerHTML={{ __html: report?.report }} />
              </Container>

              {report?.report_type?.items && (
                <Container
                  title="Item Usage"
                  path={`/dashboard/service/reports/${report?.id}/finish?stage=2`}
                >
                  <ItemConsumptions />
                </Container>
              )}
              {report?.report_type?.variables && (
                <Container
                  title="Variables"
                  path={`/dashboard/service/reports/${report?.id}/finish?stage=3`}
                >
                  <Variables />
                </Container>
              )}

              {report?.report_type?.checklist && report?.checklist?.id && (
                <Container
                  title="Checklist"
                  path={`/dashboard/service/reports/${report?.id}/finish?stage=4`}
                >
                  <Checklist />
                </Container>
              )}
            </div>
            <div>
              <button
                type="button"
                className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                onClick={onClose}
              >
                {t("Close")}{" "}
              </button>
            </div>
          </>
        )}
      </div>
    </PMDialog>
  );
}
